import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }
    html, body {
        width: 100%;
        height: 100%;
    }
    body {
        font-family: 'Lato', sans-serif;
        padding: 0;
        margin: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        background: white;

        &.fixed {
            position: fixed;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 300;
    }  
`;

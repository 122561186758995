import React from 'react';
import styled from 'styled-components';
import { animated, Spring } from 'react-spring/renderprops.cjs';

export const ContentFader = ({ children }) => {
  return (
    <Spring
      from={{ opacity: 0, transform: 'translate3d(0, -10px, 0)' }}
      to={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
    >
      {props => <Wrapper style={props}>{children}</Wrapper>}
    </Spring>
  );
};

const Wrapper = styled(animated.div)``;

import React from 'react';
import styled from 'styled-components';
import { Checkmark } from './icons';

export const Checkbox = ({ label, labelColor, checked, error, onClick }) => {
  return (
    <StyledCheckboxWrapper onClick={onClick}>
      <StyledCheckbox checked={checked} error={error}>
        {checked ? <Checkmark className="checkmark" /> : null}
      </StyledCheckbox>
      {label ? (
        <StyledCheckboxLabel
          className="checkbox-label"
          error={error}
          labelColor={labelColor}
        >
          {label}
        </StyledCheckboxLabel>
      ) : null}
    </StyledCheckboxWrapper>
  );
};

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`;

const StyledCheckbox = styled.div`
  transition: border 0.2s ease-in-out;
  flex: 0 0 auto;
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  background: rgb(235, 235, 235);
  border-radius: 2px;
  border: solid 2px
    ${props => (props.error ? 'rgb(210, 40, 0)' : 'transparent')};
  float: left;

  .checkmark {
    position: absolute;
    width: 18px;
    bottom: -1px;
    left: 5px;

    path {
      stroke: rgb(70, 70, 70);
    }
  }
`;

const StyledCheckboxLabel = styled.div`
  transition: color 0.2s ease-in-out;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: ${props =>
    props.error
      ? 'rgb(210, 40, 0)'
      : props.labelColor
      ? props.labelColor
      : '#404040'};
  margin-left: 20px;
`;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { LinkButton, ButtonGroup } from '../ui/Button';

import { Section } from '../layout/Section';
import { ContentFader } from '../layout/ContentFader';

export const Product = () => {
  return (
    <>
      <Helmet>
        <title>Kommer snart - Portcenteret Østlandet</title>
      </Helmet>
      <Section className="top bottom">
        <ContentFader>
          <>
            <h1>Siden er under utvikling!</h1>
            <p style={{ marginBottom: '20px' }}>
              Har du spørsmål angående produktet? Ring oss, eller send oss en
              hvendelese.
            </p>
            <ButtonGroup center>
              <LinkButton to="/kontakt-oss" colorTheme="primary">
                Send henvendelse
              </LinkButton>
            </ButtonGroup>
          </>
        </ContentFader>
      </Section>
    </>
  );
};

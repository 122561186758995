import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { Section } from '../layout/Section';
import { ContentFader } from '../layout/ContentFader';

export const PrivacyContent = ({ theme }) => {
  return (
    <>
      <h1>Personvernerklæring</h1>
      <p style={{ marginBottom: '20px' }}>
        Vi lagrer ikke informasjon om deg. Dataen du sender til oss via våre
        kontaktskjema blir kun sendt per e-post, og blir ikke brukt videre av
        oss bortsett fra når vi kontakter deg. All e-post kommunikasjon skjer
        via sikker e-post som bruker tls-kryptering.
      </p>
      <h3>Felter som blir sendt i henvendelser fra denne siden</h3>
      <TableWrapper>
        <Table theme={theme}>
          <thead>
            <th>Element</th>
            <th>Obligatorisk</th>
            <th>Innhentes</th>
            <th>Lagres</th>
          </thead>
          <tbody>
            <tr>
              <td>Navn</td>
              <td>Ja</td>
              <td>Ved registrering</td>
              <td>Nei</td>
            </tr>
            <tr>
              <td>Epost</td>
              <td>Ja</td>
              <td>Ved registrering</td>
              <td>Nei</td>
            </tr>
            <tr>
              <td>Mobil</td>
              <td>Ja</td>
              <td>Ved registrering</td>
              <td>Nei</td>
            </tr>
            <tr>
              <td>Melding</td>
              <td>Ja</td>
              <td>Ved registrering</td>
              <td>Nei</td>
            </tr>
          </tbody>
        </Table>
      </TableWrapper>

      <h3>Om cookies / informasjonskapsler</h3>
      <p>
        Cookies er en samling av tekst som lagres i nettleseren din for å kunne
        identifisere en brukersesjon, holde på viktig data som f.eks
        innlogginsinformasjon, eller vise statistikk på grunnlag av hvor du
        beveger deg på nettsiden.
      </p>
      <h3>Informasjonskapsler på denne siden</h3>
      <TableWrapper>
        <Table theme={theme}>
          <thead>
            <th>Navn</th>
            <th>Beskrivelse</th>
            <th>Hensikt</th>
          </thead>
          <tbody>
            <tr>
              <td>_po_privacy</td>
              <td>Lagrede personverninnstillinger</td>
              <td>Cookie som settes når man lagrer personverninnstillinger</td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>Cookie som blir satt for Google Analytics</td>
              <td>Id som brukes av Google Analytics</td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>Cookie som blir satt for Google Analytics</td>
              <td>
                Statistikk for antall besøkende, og brukerens atferdsmønster
              </td>
            </tr>
          </tbody>
        </Table>
      </TableWrapper>
    </>
  );
};

export const PrivacyPage = () => {
  return (
    <>
      <Helmet>
        <title>Personvern - Portcenteret Østlandet</title>
      </Helmet>
      <Section className="top200" left>
        <ContentFader>
          <PrivacyContent />
        </ContentFader>
      </Section>
    </>
  );
};

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: scroll;
`;

const Table = styled.table`
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;

  thead,
  tbody {
    width: 100%;
    color: ${props =>
      props.theme === 'dark' ? 'rgb(200, 200, 200)' : 'rgb(100, 100, 100)'};
  }

  thead {
    background: ${props =>
      props.theme === 'dark' ? 'rgb(40, 40, 40)' : 'rgb(230, 230, 230)'};
    border-bottom: solid 2px
      ${props => (props.theme === 'dark' ? 'rgb(50, 50, 50)' : 'white')};
    th {
      border-right: solid 2px
        ${props => (props.theme === 'dark' ? 'rgb(50, 50, 50)' : 'white')};

      &:last-child {
        border-right: none;
      }
    }
  }

  tbody {
    tr {
      background: ${props =>
        props.theme === 'dark' ? 'rgb(30, 30, 30)' : 'rgb(230, 230, 230)'};
      td {
        border-right: solid 2px
          ${props => (props.theme === 'dark' ? 'rgb(50, 50, 50)' : 'white')};

        &:last-child {
          border-right: none;
        }
      }

      &:nth-of-type(even) {
        background: ${props =>
          props.theme === 'dark' ? 'rgb(20, 20, 20)' : 'white'};
      }
    }
  }

  th,
  td {
    padding: 10px;
    text-align: left;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { Section } from '../layout/Section';
import { Top } from '../layout/Top';
import { Item, ItemWrapper, WhiteItem } from '../ui/Item';
import {
  LeddportIcon,
  RulleportIcon,
  VippeportIcon,
  SideportIcon,
  AutomaticIcon,
  PartsIcon,
  SparePartsIcon
} from '../ui/icons';
import { InspectionForm } from '../layout/InspectionForm';

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Garasjeporter i ypperste kvalitet fra Crawford - Portcenteret
          Østlandet
        </title>
        <meta
          name="description"
          content={`Garasjeporter med 10 års garanti. Velg Crawford Garasjeporter
          fra Portcenteret Østlandet. Din leverandør og montør av Crawford Garasjeporter på hele østlandet.`}
        />
      </Helmet>
      <Top />
      <Section>
        <h1>Hva er du på utkikk etter?</h1>
        <p style={{ marginBottom: '50px' }}>
          Vi tilbyr kvalitetsporter og tilbehør fra Crawford garasjeporter.
        </p>
        <ItemWrapper>
          <Item
            to="/produkter/leddheisport"
            className="flex-center"
            icon={LeddportIcon}
            label="Leddheisport"
          />
          {/*<Item
            to="/produkter/rulleporter-gitter"
            className="flex-center"
            icon={RulleportIcon}
            label="Rulleport og gitter"
           />*/}
          {/*<Item
            to="/produkter/vippeporter"
            className="flex-center i165"
            icon={VippeportIcon}
            label="Vippeport"
          />*/}
          <Item
            to="/produkter/sidegående-leddport"
            className="flex-center"
            icon={SideportIcon}
            label="Sidegående leddport"
          />
          <Item
            to="/produkter/portautomatikk"
            className="flex-center"
            icon={AutomaticIcon}
            label="Portautomatikk"
          />
          <Item
            to="/produkter/tilbehør"
            icon={PartsIcon}
            className="flex-center"
            label="Tilbehør"
          />
          <Item
            to="/kontakt-oss"
            icon={SparePartsIcon}
            label="Service og reservedeler"
          />
          <WhiteItem>
            <h3>
              Finner du ikke det du leter etter? <span>Ring oss</span>, så vil
              vi hjelpe deg.
            </h3>
          </WhiteItem>
        </ItemWrapper>
      </Section>
      <InspectionForm sectionClass="dark" />
    </>
  );
};

import React, { useState } from 'react';
import styled from 'styled-components';

export const Input = ({ placeholder, value, error, onChange }) => {
  const [focus, setFocus] = useState(false);
  return (
    <StyledInputWrapper error={error}>
      <StyledPlaceholder error={error} focus={focus || value !== ''}>
        {placeholder}
      </StyledPlaceholder>
      <StyledInput
        error={error}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        value={value}
        onChange={onChange}
      />
    </StyledInputWrapper>
  );
};

const StyledInputWrapper = styled.div`
  transition: border 0.2s ease-in-out;
  position: relative;
  width: 100%;
  background: rgb(235, 235, 235);
  border: solid 2px
    ${props => (props.error ? 'rgb(210, 40, 0)' : 'transparent')};
  border-radius: 2px;
`;

const StyledPlaceholder = styled.div`
  transition: font-size 0.2s ease-in-out, transform 0.2s ease-in-out,
    color 0.2s ease-in-out;
  position: absolute;
  left: 10px;
  font-size: ${props => (props.focus ? '14px' : '18px')};
  pointer-events: none;
  color: ${props => (props.error ? 'rgb(210, 40, 0)' : '#404040')};
  transform: ${props =>
    props.focus ? 'translate3d(0px, 8px, 0)' : 'translate3d(0, 20px, 0);'};
`;

const StyledInput = styled.input`
  transition: color 0.2s ease-in-out;
  padding: 30px 20px 10px 10px;
  margin: 0;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  font-size: 18px;
  color: ${props => (props.error ? 'rgb(210, 40, 0)' : '#404040')};
`;

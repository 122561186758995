import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { Button } from '../../ui/Button';
import { Section } from '../../layout/Section';
import { ContentFader } from '../../layout/ContentFader';

import superior from '../../../../../public/images/superior_1.png';
import smart from '../../../../../public/images/smart.png';
import fjernkontroll from '../../../../../public/images/zendesign.png';

const Heading = styled.div`
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  h1 {
    margin-top: 0;
  }
`;

export const Leddheisport = ({ history }) => {
  return (
    <>
      <Helmet>
        <title>Leddheisport - Portcenteret Østlandet</title>
      </Helmet>
      <Section className="top150" left>
        <ContentFader>
          <>
            <Heading>
              <h2>Crawford</h2>
              <h1>Leddheisporter</h1>
            </Heading>
            <p>
              <b>
                Leddheisporter fra Crawford kan tilpasses i størrelse, design og
                funksjonalitet, så de passer til dine personlige behov og smak.
              </b>
            </p>
            <p>
              Leddheisporter fra Crawford er preget av deres unike kombinasjon
              av estetikk og funksjon. 42 mm tykkelse gir god isolering,
              stabilitet og høy grad av sikkerhet. Kulelagre sikrer en nesten
              lydløs betjening, mens vår beskyttelse mot klemte fingre
              forhindrer skader på hender når man bruker våre leddheisporter. En
              bredt sortimang av modeller, farger og overflater gir en utrolig
              frihet til å tilpasse din leddheisport, slik at man kan lage en
              garasjeport tilpasset ethvert hjem og smak.
            </p>
            <p>
              Vi har spesialisert oss på garasjeporter og fordi de er laget for
              å holde, får du hele{' '}
              <b style={{ color: 'rgb(60, 60, 60)' }}>10 års garanti</b> på våre
              garasjeporter, inklusiv alle slitedeler når du kjøper en
              garasjeport fra Crawford.
            </p>
          </>
          <h2 style={{ fontWeight: '600' }}>Varianter</h2>
          <ContentWrapper>
            <ContentColumn>
              <PortType colorTheme="tertiary">
                <PortColumn className="image">
                  <TopImage image={superior} />
                </PortColumn>
                <PortColumn className="text">
                  <h4>Superior</h4>
                  <p>
                    Crawford Superior leddporter imponerer med ekstra sikkerhet
                    og stabilitet. En gjennomgangsdør og mange unike
                    ekstrafunksjoner oppfyller alle ønsker og behov.
                  </p>
                  <Button
                    colorTheme="primary"
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(
                          'https://crawfordgarasjeporter.no/no/garasjeporter/leddporter/leddheiseporter/superior/',
                          '_blank'
                        );
                      }
                    }}
                  >
                    Les mer
                  </Button>
                </PortColumn>
              </PortType>
            </ContentColumn>
            <ContentColumn>
              <PortType colorTheme="quadranery">
                <PortColumn className="image">
                  <TopImage image={smart} />
                </PortColumn>
                <PortColumn className="text">
                  <h4 style={{ color: 'black' }}>Smart</h4>
                  <p style={{ color: 'black' }}>
                    Crawford Smart er fremragende både av utseende, funksjon og
                    kostnadseffektivitet. En fantastisk leddport som har satt en
                    ny standard i bransjen.
                  </p>
                  <Button
                    colorTheme="primary"
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(
                          'https://crawfordgarasjeporter.no/no/garasjeporter/leddporter/leddheiseporter/smart/',
                          '_blank'
                        );
                      }
                    }}
                  >
                    Les mer
                  </Button>
                </PortColumn>
              </PortType>
            </ContentColumn>
          </ContentWrapper>
          <h2 style={{ fontWeight: '600' }}>Tilbehør</h2>
          <ContentWrapper>
            <ContentColumn>
              <PortType style={{ background: 'rgb(230, 230, 230)' }}>
                <PortColumn className="image">
                  <TopImage image={fjernkontroll} />
                </PortColumn>
                <PortColumn className="text">
                  <h4 style={{ color: 'black' }}>Uendelige muligheter</h4>
                  <p style={{ color: 'black' }}>
                    Åpne din leddheisport med et lett trykk på håndsenderen,
                    eller bruk kodelås til å åpne din garasjeport. Vi har et
                    stort utvalg av tilbehør du kan velge til din garasjeport.
                  </p>
                  <Button
                    colorTheme="primary"
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        history.push('/produkter/tilbehør');
                      }
                    }}
                  >
                    Les mer om tilbehør
                  </Button>
                </PortColumn>
              </PortType>
            </ContentColumn>
          </ContentWrapper>
        </ContentFader>
      </Section>
    </>
  );
};

const TopImage = styled.div`
  background: ${props => `url(${props.image}) no-repeat`};
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 1100px) {
    flex-flow: column;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  width: calc(100% - 20px);
  margin-right: 20px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const PortType = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors[props.colorTheme]};

  @media screen and (max-width: 550px) {
    flex-flow: column;

    .text {
      width: 100%;
      float: left;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    color: white;
    margin: 0;
  }
  p {
    color: white;
  }
`;

const PortColumn = styled.div`
  width: 100%;
  height: auto;

  &.text {
    padding: 20px;
  }
  &.image {
    width: 300px;
    padding-left: 20px;
  }
  img {
    height: 200px;
  }
`;

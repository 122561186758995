import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Mutation } from 'react-apollo';
import { POST_INSPECTION } from '../../../client/__graphql__/mutations';

import { Section } from './Section';
import { Form, FormSuccess, FormRow, FormColumn } from '../ui/Form';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { Checkbox } from '../ui/Checkbox';
import { validateEmail } from '../../utils/helpers';
import { ContentFader } from './ContentFader';

export const InspectionForm = ({ sectionClass }) => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState(false);
  const [submitted, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  return (
    <Mutation
      mutation={POST_INSPECTION}
      onCompleted={({ inspection }) => {
        if (inspection && inspection.success) {
          setSuccess(true);
        }
      }}
    >
      {(post, { loading }) => (
        <Section className={sectionClass}>
          <ContentFader>
            <>
              <h1 className="black">Ønsker du gratis befaring?</h1>
              <p className="black">
                Send oss navn, mobil, og e-post. Så vil vi ta kontakt med deg så
                raskt som mulig.
              </p>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  setSubmit(true);
                  if (name && mobile && validateEmail(email) && checked) {
                    post({
                      variables: {
                        input: {
                          name,
                          mobile,
                          email
                        }
                      }
                    });
                  }
                }}
              >
                <FormSuccess
                  show={success}
                  heading="Takk for din henvendelse!"
                  subText="Vi vil kontakte deg innen kort tid."
                />
                <FormRow>
                  <FormColumn>
                    <Input
                      error={submitted && !name}
                      placeholder="Navn"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </FormColumn>
                  <FormColumn>
                    <Input
                      error={
                        submitted && (!mobile || (mobile && mobile.length < 8))
                      }
                      placeholder="Mobil"
                      value={mobile}
                      onChange={e => setMobile(e.target.value)}
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <Input
                      error={
                        (submitted && !email) ||
                        (submitted && email && !validateEmail(email))
                      }
                      placeholder="E-post"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </FormColumn>
                </FormRow>
                <FormRow className="mt20">
                  <FormColumn>
                    <Checkbox
                      checked={checked}
                      error={submitted && !checked}
                      onClick={() => setChecked(!checked)}
                      label="Jeg samtykker til at jeg kan bli kontaktet via mobil eller e-post."
                    />
                  </FormColumn>
                </FormRow>
                <FormRow className="mt20">
                  <FormColumn>
                    <Button colorTheme="primary">Få gratis befaring</Button>
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <PrivacyLink to="/personvern">
                    Personvernerklæring
                  </PrivacyLink>
                </FormRow>
              </Form>
            </>
          </ContentFader>
        </Section>
      )}
    </Mutation>
  );
};

const PrivacyLink = styled(props => <Link {...props} />)`
  margin-top: 20px;
  color: #404040;
`;

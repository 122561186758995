import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 2.5rem;

  &.flex-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 700px) {
    padding: 0 1.875rem;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { Section } from '../layout/Section';
import { ContentFader } from '../layout/ContentFader';

export const NoMatch = () => {
  return (
    <>
      <Helmet>
        <title>Finner ikke det du leter etter - Portcenteret Østlandet</title>
      </Helmet>
      <Section className="top bottom">
        <ContentFader>
          <>
            <h1>Finner ikke det du leter etter</h1>
            <p>Er du sikker på at du skrev riktig?</p>
          </>
        </ContentFader>
      </Section>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const ProgressiveImage = ({ src, preview, imageLoaded }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded === true) {
      imageLoaded();
    }
  }, [loaded]);
  return (
    <StyledImageWrapper>
      <StyledProgressiveImagePreview
        loaded={loaded}
        style={{
          background: `url(/images/${preview}`
        }}
      />
      <StyledProgressiveImage
        style={{
          background: `url(${loaded ? `/images/${src}` : ''})`
        }}
      />
      <img
        alt="preload-image"
        className="load-image"
        src={`/images/${src}`}
        onLoad={() => {
          setTimeout(() => {
            setLoaded(true);
          }, 250);
        }}
      />
    </StyledImageWrapper>
  );
};

const StyledProgressiveImagePreview = styled.div`
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  width: 105%;
  height: 105%;
  background-size: cover !important;
  background-position: center center !important;
  opacity: ${props => (props.loaded ? 0 : 1)};
  filter: blur(10px);
  z-index: 2;
`;

const StyledProgressiveImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  z-index: 1;
`;

const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .load-image {
    position: absolute;
    width: 1px;
    height: 1px;
  }
`;

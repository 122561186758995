import React, { useEffect } from 'react';
import styled from 'styled-components';

import { withApollo, Query } from 'react-apollo';
import { Route } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { MENU_STATE } from '../../../client/__graphql__/queries';
import { TOGGLE_MENU } from '../../../client/__graphql__/mutations';

import { Container } from './Container';
import { Logo } from '../ui/Logo';
import { Menu } from '../ui/Menu';
import { MenuIcon } from '../ui/MenuIcon';
import { BreadCrumbs } from './Breadcrumbs';

export const Header = withApollo(({ client, location }) => {
  return (
    <Query query={MENU_STATE}>
      {({ data: { menu } }) => (
        <>
          <StyledHeader useBackground={location && location.pathname !== '/'}>
            <Container className="flex-center">
              <Logo
                show={menu && menu.show}
                useDark={
                  (location && location.pathname !== '/') || (menu && menu.show)
                }
              />
              <MenuIcon
                state={menu}
                useDark={location && location.pathname !== '/'}
                onClick={() => {
                  client.writeData({
                    data: {
                      menu: {
                        __typename: 'Menu',
                        show: !menu.show
                      }
                    }
                  });
                }}
              />
            </Container>
            <Route
              path="/:p1?/:p2?/:p3?/:p4?"
              render={props => (
                <BreadCrumbs {...props} show={menu && menu.show} />
              )}
            />
          </StyledHeader>

          <Menu state={menu} />
        </>
      )}
    </Query>
  );
});

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${props => (props.useBackground ? '30px 0 30px 0' : '30px 0')};
  z-index: 9998;
  background: ${props => (props.useBackground ? 'none' : 'none')};
`;

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { Section } from '../layout/Section';
import { InspectionForm } from '../layout/InspectionForm';

export const Inspection = () => {
  return (
    <>
      <Helmet>
        <title>Gratis befaring - Portcenteret Østlandet</title>
      </Helmet>
      <InspectionForm sectionClass="top200" />
    </>
  );
};

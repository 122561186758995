import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { Button } from '../../ui/Button';
import { Section } from '../../layout/Section';
import { ContentFader } from '../../layout/ContentFader';

import superior from '../../../../../public/images/superior_1.png';
import smart from '../../../../../public/images/smart.png';
import fjernkontroll from '../../../../../public/images/zendesign.png';
import mottaker from '../../../../../public/images/mottaker.png';
import kodeplate from '../../../../../public/images/kodelaas.png';
import nokkel from '../../../../../public/images/nokkel.png';

const Heading = styled.div`
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  h1 {
    margin-top: 0;
  }
`;

export const Parts = () => {
  return (
    <>
      <Helmet>
        <title>Service og reservedeler - Portcenteret Østlandet</title>
      </Helmet>
      <Section className="top150" left>
        <ContentFader>
          <>
            <Heading>
              <h1>Service og reservedeler</h1>
            </Heading>
            <p>
              Har du en Crawford garasjeport som trenger service, eller nye
              deler? Kontakt oss for assistanse.
            </p>
          </>
        </ContentFader>
      </Section>
    </>
  );
};

const TopImage = styled.div`
  background: ${props => `url(${props.src}) no-repeat`};
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 1100px) {
    flex-flow: column;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  width: calc(100% - 20px);
  margin-right: 20px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

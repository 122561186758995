import React from 'react';

export const Frame = ({ className, fill }) => (
  <svg
    width="334"
    height="206"
    viewBox="0 0 334 206"
    fill="none"
    className={className}
  >
    <path d="M329 206V5H5V206" stroke={fill} strokeWidth="15px" />
  </svg>
);

export const Facebook = ({ className }) => (
  <svg
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    className={className}
  >
    <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
  </svg>
);

export const Checkmark = ({ className }) => (
  <svg
    width="26"
    height="29"
    viewBox="0 0 26 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M2 10L10.5 24.5L23.5 1" stroke="#B1C628" strokeWidth="4" />
  </svg>
);

export const MarkerIcon = ({ className, fill }) => (
  <svg
    width="56"
    height="93"
    viewBox="0 0 56 93"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 92.9091C35.6364 92.9091 56 59.5 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 59.5 20.3636 92.9091 28 92.9091ZM28.3182 44.5455C37.9832 44.5455 45.8182 36.7105 45.8182 27.0455C45.8182 17.3806 37.9832 9.54554 28.3182 9.54554C18.6532 9.54554 10.8182 17.3806 10.8182 27.0455C10.8182 36.7105 18.6532 44.5455 28.3182 44.5455Z"
      fill={fill}
    />
  </svg>
);

export const LeddportIcon = ({ className, fill }) => (
  <svg
    width="189"
    height="161"
    viewBox="0 0 189 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M186.78 160.025V2.83844H2.60168V160.025"
      stroke="rgb(40, 40, 40)"
      strokeWidth="3.96936"
    />
    <path
      d="M14.9067 8.39557H174.872L181.223 30.624H8.15881L14.9067 8.39557Z"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="8.15881"
      y="36.5933"
      width="173.064"
      height="22.2284"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="8.15881"
      y="64.7911"
      width="173.064"
      height="22.2284"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="8.15881"
      y="92.9889"
      width="173.064"
      height="22.2284"
      fill="rgb(40, 40, 40)"
    />
  </svg>
);

export const RulleportIcon = ({ className, fill }) => (
  <svg
    width="189"
    height="161"
    viewBox="0 0 189 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M186.78 160.025V2.83844H2.60168V160.025"
      stroke="rgb(40, 40, 40)"
      strokeWidth="3.96936"
    />
    <rect x="8" y="9" width="173" height="8" fill="rgb(40, 40, 40)" />
    <rect x="8" y="22" width="173" height="8" fill="rgb(40, 40, 40)" />
    <rect x="8" y="35" width="173" height="8" fill="rgb(40, 40, 40)" />
    <rect x="8" y="48" width="173" height="8" fill="rgb(40, 40, 40)" />
    <rect x="8" y="61" width="173" height="8" fill="rgb(40, 40, 40)" />
    <rect x="8" y="74" width="173" height="8" fill="rgb(40, 40, 40)" />
    <rect x="8" y="87" width="173" height="8" fill="rgb(40, 40, 40)" />
    <rect x="8" y="100" width="173" height="8" fill="rgb(40, 40, 40)" />
  </svg>
);

export const VippeportIcon = ({ className, fill }) => (
  <svg
    width="226"
    height="161"
    viewBox="0 0 226 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6017 0.85376H18.617V2.83844V44.4513L22.5864 33.6161V4.82312H202.795V34.0966L206.765 44.5762V2.83844V0.85376H204.78H20.6017ZM206.765 98H202.795V160.025H206.765V98ZM22.5864 98H18.617V160.025H22.5864V98Z"
      fill="rgb(40, 40, 40)"
    />
    <path
      d="M4.28104 93L34.4205 10H190.507L221.669 93H4.28104Z"
      fill="none"
      stroke="rgb(40, 40, 40)"
      strokeWidth="6"
    />
  </svg>
);

export const SideportIcon = ({ className, fill }) => (
  <svg
    width="189"
    height="160"
    viewBox="0 0 189 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M186.78 159.187V2H2.60168V159.187"
      stroke="rgb(40, 40, 40)"
      strokeWidth="3.96936"
    />
    <path
      d="M9 153.112L8.99999 13.5413L31 8L31 159L9 153.112Z"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="37"
      y="159"
      width="151"
      height="23"
      transform="rotate(-90 37 159)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="66"
      y="159"
      width="151"
      height="22"
      transform="rotate(-90 66 159)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="94"
      y="159"
      width="151"
      height="22"
      transform="rotate(-90 94 159)"
      fill="rgb(40, 40, 40)"
    />
  </svg>
);

export const AutomaticIcon = ({ className, fill }) => (
  <svg
    width="202"
    height="86"
    viewBox="0 0 202 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 2.99988H158.971C181.079 2.99988 199 20.9214 199 43.0286V43.0286C199 65.1359 181.079 83.0574 158.971 83.0574H0"
      stroke="rgb(40, 40, 40)"
      strokeWidth="5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.709 9.65887C161.324 8.9646 160.323 8.97419 159.951 9.6757L154.335 20.2773C154.086 20.7478 153.512 20.9405 153.029 20.7157L142.224 15.6838C141.506 15.3498 140.704 15.9414 140.811 16.7253L142.438 28.67C142.509 29.1929 142.162 29.6812 141.644 29.7853L129.95 32.1378C129.178 32.2929 128.88 33.2366 129.422 33.8069L137.696 42.5161C138.058 42.8967 138.063 43.4921 137.709 43.8796L129.603 52.7455C129.072 53.3261 129.389 54.2639 130.163 54.4042L141.9 56.5324C142.42 56.6266 142.776 57.1082 142.715 57.6324L141.317 69.606C141.225 70.3918 142.039 70.968 142.749 70.6203L153.457 65.3825C153.935 65.1485 154.512 65.3302 154.771 65.7958L160.589 76.288C160.974 76.9823 161.975 76.9727 162.347 76.2712L167.963 65.6695C168.212 65.1991 168.786 65.0064 169.269 65.2311L180.075 70.2631C180.792 70.5971 181.594 70.0054 181.488 69.2216L179.86 57.2769C179.789 56.7539 180.136 56.2656 180.654 56.1615L192.349 53.8091C193.12 53.6539 193.418 52.7102 192.876 52.1399L184.602 43.4308C184.241 43.0502 184.235 42.4547 184.589 42.0673L192.695 33.2013C193.226 32.6208 192.91 31.683 192.136 31.5426L180.398 29.4144C179.878 29.3203 179.522 28.8387 179.583 28.3145L180.981 16.3408C181.073 15.5551 180.259 14.9789 179.549 15.3265L168.842 20.5644C168.363 20.7983 167.786 20.6166 167.528 20.1511L161.709 9.65887ZM159.935 62.4824C170.285 63.4038 179.43 55.6715 180.362 45.2118C181.293 34.7521 173.657 25.5259 163.307 24.6044C152.957 23.683 143.811 31.4153 142.88 41.875C141.949 52.3347 149.585 61.561 159.935 62.4824Z"
      fill="rgb(40, 40, 40)"
    />
    <mask id="path-3-inside-1" fill="white">
      <path d="M172.728 44.0213C172.389 50.2748 167.044 55.0694 160.791 54.7304C154.537 54.3913 149.743 49.047 150.082 42.7934C150.421 36.5399 155.765 31.7453 162.019 32.0843C168.272 32.4234 173.067 37.7677 172.728 44.0213Z" />
    </mask>
    <path
      d="M162.019 32.0843L162.073 31.0858L162.019 32.0843ZM171.729 43.9671C171.42 49.6692 166.547 54.041 160.845 53.7318L160.737 55.7289C167.542 56.0979 173.357 50.8804 173.726 44.0754L171.729 43.9671ZM160.845 53.7318C155.143 53.4227 150.771 48.5496 151.08 42.8476L149.083 42.7393C148.714 49.5443 153.932 55.36 160.737 55.7289L160.845 53.7318ZM151.08 42.8476C151.389 37.1455 156.262 32.7737 161.964 33.0828L162.073 31.0858C155.268 30.7168 149.452 35.9343 149.083 42.7393L151.08 42.8476ZM161.964 33.0828C167.667 33.392 172.038 38.265 171.729 43.9671L173.726 44.0754C174.095 37.2704 168.878 31.4547 162.073 31.0858L161.964 33.0828Z"
      fill="rgb(40, 40, 40)"
      mask="url(#path-3-inside-1)"
    />
    <rect x="14" y="5" width="9" height="5" fill="rgb(40, 40, 40)" />
    <rect x="33" y="5" width="9" height="5" fill="rgb(40, 40, 40)" />
    <rect x="52" y="5" width="9" height="5" fill="rgb(40, 40, 40)" />
    <rect x="71" y="5" width="9" height="5" fill="rgb(40, 40, 40)" />
    <rect x="90" y="5" width="9" height="5" fill="rgb(40, 40, 40)" />
    <rect x="109" y="5" width="9" height="5" fill="rgb(40, 40, 40)" />
    <rect x="128" y="5" width="9" height="5" fill="rgb(40, 40, 40)" />
    <rect x="147" y="5" width="9" height="5" fill="rgb(40, 40, 40)" />
    <rect
      x="167.801"
      y="6.15271"
      width="9"
      height="5"
      transform="rotate(20.7538 167.801 6.15271)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="188.274"
      y="18"
      width="9"
      height="5"
      transform="rotate(58.7309 188.274 18)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="197.348"
      y="38.1917"
      width="9"
      height="5"
      transform="rotate(91.5482 197.348 38.1917)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="192.663"
      y="60.8499"
      width="9"
      height="5"
      transform="rotate(124.831 192.663 60.8499)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="177.238"
      y="77.2297"
      width="9"
      height="5"
      transform="rotate(158.621 177.238 77.2297)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="157.149"
      y="80.9977"
      width="9"
      height="5"
      transform="rotate(178.249 157.149 80.9977)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="138.043"
      y="80.9998"
      width="9"
      height="5"
      transform="rotate(179.508 138.043 80.9998)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="119.043"
      y="80.9998"
      width="9"
      height="5"
      transform="rotate(179.508 119.043 80.9998)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="100.043"
      y="80.9998"
      width="9"
      height="5"
      transform="rotate(179.508 100.043 80.9998)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="81.0426"
      y="80.9998"
      width="9"
      height="5"
      transform="rotate(179.508 81.0426 80.9998)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="62.0426"
      y="80.9998"
      width="9"
      height="5"
      transform="rotate(179.508 62.0426 80.9998)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="43.0426"
      y="80.9998"
      width="9"
      height="5"
      transform="rotate(179.508 43.0426 80.9998)"
      fill="rgb(40, 40, 40)"
    />
    <rect
      x="24.0426"
      y="80.9998"
      width="9"
      height="5"
      transform="rotate(179.508 24.0426 80.9998)"
      fill="rgb(40, 40, 40)"
    />
  </svg>
);

export const PartsIcon = ({ className, fill }) => (
  <svg
    width="179"
    height="184"
    viewBox="0 0 179 184"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M131.337 148.46H148.36"
      stroke="rgb(40, 40, 40)"
      strokeWidth="3.40449"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="140.274" cy="101.223" r="22.5548" fill="rgb(40, 40, 40)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.489 71.092C131.918 73.1978 135.954 74.4121 140.274 74.4121C152.731 74.4121 162.829 64.314 162.829 51.8573C162.829 39.4007 152.731 29.3026 140.274 29.3026C136.126 29.3026 132.24 30.4223 128.9 32.3759C134.4 43.9385 134.832 57.791 128.891 70.2485L128.489 71.092Z"
      fill="rgb(40, 40, 40)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.748 128.571C105.832 145.831 121.34 158.673 139.848 158.673C160.898 158.673 178.067 142.062 178.963 121.234C178.988 120.666 179 120.095 179 119.522V118.245V51.4318C179 29.8089 161.471 12.2801 139.848 12.2801C131.873 12.2801 124.456 14.6645 118.268 18.7592C119.158 19.5276 120.012 20.3281 120.83 21.158C126.338 17.6905 132.859 15.6846 139.848 15.6846C159.591 15.6846 175.596 31.6892 175.596 51.4318V118.245V119.522C175.596 119.715 175.594 119.908 175.591 120.101L175.201 120.649C157.906 144.965 122.354 144.77 105.027 121.695L101.748 128.571ZM104.853 126.851C123.231 147.418 156.292 147.732 174.916 126.494C171.673 142.899 157.205 155.269 139.848 155.269C122.618 155.269 108.234 143.078 104.853 126.851Z"
      fill="rgb(40, 40, 40)"
    />
    <path
      d="M40.6622 136.108L56.0271 143.435"
      stroke="rgb(40, 40, 40)"
      strokeWidth="3.40449"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="90.3087"
      cy="52.7585"
      r="22.5548"
      transform="rotate(25.4944 90.3087 52.7585)"
      fill="rgb(40, 40, 40)"
    />
    <circle
      cx="69.0608"
      cy="97.3169"
      r="22.5548"
      transform="rotate(25.4944 69.0608 97.3169)"
      fill="rgb(40, 40, 40)"
    />
    <path
      d="M26.3928 98.865C31.1251 129.584 65.3167 146.8 93.0528 131.373M95.3867 128.127L92.2254 129.885C64.7483 145.168 30.6879 127.162 27.7473 95.9593L56.3049 36.0721C65.2072 17.4033 87.558 9.48601 106.227 18.3884C124.896 27.2907 132.813 49.6415 123.911 68.3103L95.3867 128.127ZM44.6812 147.454C27.459 139.241 19.3866 119.584 25.2281 101.93C31.615 131.54 64.8361 147.851 92.4977 133.601C82.529 149.483 62.0175 155.721 44.6812 147.454Z"
      stroke="rgb(40, 40, 40)"
      strokeWidth="3.40449"
    />
  </svg>
);

export const SparePartsIcon = ({ className, fill }) => (
  <svg
    width="98"
    height="154"
    viewBox="0 0 98 154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{ marginTop: '20px' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.0001 4.45224V23.5018H35.0001V6.98991C27.7096 12.466 23.0001 21.1823 23.0001 30.993C23.0001 37.8244 25.2834 44.125 29.1316 49.1707C27.6161 49.2288 26.0909 49.3902 24.5712 49.6494C21.0492 44.2934 19.0001 37.8825 19.0001 30.993C19.0001 18.8256 25.3914 8.15096 35.0001 2.14326C36.2789 1.34367 37.6147 0.626761 39.0001 0V4.45224ZM39.0001 101.874V139.993C39.0001 147.725 45.2681 153.993 53.0001 153.993C60.732 153.993 67.0001 147.725 67.0001 139.993V133.761L63.0001 129.206V139.993C63.0001 145.516 58.5229 149.993 53.0001 149.993C47.4772 149.993 43.0001 145.516 43.0001 139.993V106.43L39.0001 101.874ZM63.0001 96.0458L67.0001 100.601V61.9861C78.7942 56.6501 87.0001 44.7799 87.0001 30.993C87.0001 18.8256 80.6087 8.15096 71.0001 2.14326C69.7212 1.34367 68.3854 0.626761 67.0001 0V4.45225V23.5018C67.0001 23.5018 67.0001 34.007 53.0001 34C39.0001 33.993 39.0001 23.5018 39.0001 23.5018C35.0001 23.5018 35.0001 23.5044 35.0001 23.5071L35.0001 23.5127L35.0001 23.5247L35.0003 23.5517C35.0005 23.5711 35.0009 23.5932 35.0014 23.6179C35.0025 23.6674 35.0043 23.7274 35.0074 23.7973C35.0136 23.9371 35.025 24.1172 35.0455 24.3321C35.0863 24.7605 35.1643 25.3355 35.3142 26.0095C35.6117 27.3472 36.2088 29.1484 37.4224 30.9678C40.0048 34.8396 44.8343 37.9959 52.9981 38C61.1642 38.0041 65.9955 34.847 68.5784 30.972C69.7919 29.1516 70.3888 27.3489 70.6861 26.0105C70.8359 25.3361 70.9139 24.7608 70.9547 24.3322C70.9752 24.1172 70.9865 23.937 70.9927 23.7973C70.9958 23.7273 70.9976 23.6673 70.9987 23.6179C70.9992 23.5932 70.9996 23.5711 70.9998 23.5517L71 23.5247L71 23.5127L71.0001 23.5071C71.0001 23.5044 71.0001 23.5018 67.0001 23.5018H71.0001V6.98991C78.2906 12.466 83.0001 21.1823 83.0001 30.993C83.0001 43.1518 75.7666 53.6295 65.3513 58.3417L63.0001 59.4055V61.9861V96.0458Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5911 76.7406L11.5687 67.792L9.69373 65.7006C9.30691 66.5784 8.98132 67.4777 8.71731 68.3919C6.73365 75.2605 8.2267 82.9666 13.3508 88.6823C19.1569 95.1587 28.0105 97.2791 35.798 94.8188L68.6492 131.463C71.9054 135.095 77.4894 135.4 81.1216 132.144C84.7537 128.887 85.0585 123.303 81.8023 119.671L48.9511 83.0271C52.2443 75.5536 51.1001 66.5218 45.294 60.0454C40.17 54.3297 32.6722 52.0068 25.6285 53.2312C24.691 53.3941 23.7616 53.6199 22.8468 53.9089L24.7218 56.0004L32.7442 64.9489C32.7442 64.9489 37.1682 69.8838 30.5887 75.7763C24.0093 81.6689 19.5911 76.7406 19.5911 76.7406ZM27.6695 55.5079L34.6232 63.2644L32.7442 64.9489C34.6232 63.2644 34.6243 63.2656 34.6254 63.2669L34.6278 63.2695L34.6328 63.2752L34.6441 63.288L34.6714 63.3195C34.6918 63.3432 34.7162 63.3721 34.7442 63.4063C34.8001 63.4746 34.8707 63.564 34.9516 63.6736C35.1129 63.8921 35.3186 64.1952 35.5322 64.5751C35.9562 65.329 36.4349 66.4272 36.6316 67.7934C37.0501 70.7014 36.1101 74.2191 32.2723 77.6562C28.4357 81.0923 24.8378 81.6434 21.9941 80.9122C20.6579 80.5686 19.6188 79.9739 18.9157 79.4708C18.5614 79.2173 18.2826 78.9801 18.0831 78.7961C17.9829 78.7037 17.9017 78.6239 17.84 78.5609C17.8091 78.5293 17.7829 78.5019 17.7616 78.4791L17.7332 78.4485L17.7217 78.4359L17.7167 78.4303L17.7143 78.4276C17.7132 78.4264 17.712 78.4251 19.5911 76.7406L17.712 78.4251L10.7584 70.6686C9.63978 76.3113 11.0982 82.3891 15.2298 86.9978C20.3502 92.7094 28.1607 94.5851 35.0378 92.4125L36.5902 91.922L37.677 93.1342L70.5282 129.778C72.854 132.373 76.8427 132.59 79.4371 130.265C82.0314 127.939 82.2491 123.95 79.9233 121.356L47.0721 84.7116L45.9853 83.4994L46.6418 82.0095C49.55 75.4097 48.5354 67.4415 43.415 61.7299C39.2834 57.1213 33.4004 55.0101 27.6695 55.5079Z"
      fill="black"
    />
  </svg>
);

import React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring/web.cjs';

export const Form = styled.form`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
`;

export const FormSuccess = ({ show, heading, subText }) => {
  const props = useSpring({
    from: { opacity: 0, pointerEvents: 'none' },
    to: { opacity: show ? 1 : 0, pointerEvents: show ? 'auto' : 'none' }
  });
  return (
    <StyledFormSuccess style={props}>
      {heading ? <h2>{heading}</h2> : null}
      {subText ? <p>{subText}</p> : null}
    </StyledFormSuccess>
  );
};

export const StyledFormSuccess = styled(animated.div)`
  position: absolute;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
  color: #404040;

  h2 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 0;
  }

  p {
    color: black;
    margin-top: 10px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &.mt20 {
    margin-top: 20px;
  }

  @media screen and (max-width: 780px) {
    flex-flow: column;
    margin-bottom: 0;
  }
`;

export const FormColumn = styled.div`
  width: 100%;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 780px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled.button`
  transition: box-shadow 0.2s ease-in-out;
  flex: 0 0 auto;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  outline: none;
  border: none;
  border-radius: 0;
  background: ${props => props.theme.colors[props.colorTheme]};
  user-select: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    cursor: pointer;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  }
`;

export const LinkButton = styled(props => <Link {...props} />)`
  flex: 0 0 auto;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  outline: none;
  border: none;
  border-radius: 0;
  background: ${props => props.theme.colors[props.colorTheme]};
  margin-right: 20px;
  text-decoration: none;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-flow: row;
  ${props => (props.center ? 'justify-content: center;' : '')}

  button {
    margin-right: 1.25rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 600px) {
    flex-flow: column;

    button {
      margin-right: 0 !important;
      text-align: center;
      margin-bottom: 10px;
    }

    a {
      text-align: center;
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }
`;

import React, { useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import styled from 'styled-components';
import { Spring } from 'react-spring/renderprops.cjs';
import { withApollo, Query } from 'react-apollo';
import { MENU_STATE } from '../../../client/__graphql__/queries';
import { MenuIcon } from './MenuIcon';

export const Menu = withApollo(({ state, client }) => {
  let hideMenu = () => {
    client.writeData({
      data: {
        menu: {
          __typename: 'Menu',
          show: false
        }
      }
    });
  };

  useEffect(() => {
    let body = null;
    if (typeof document !== 'undefined') {
      body = document.getElementsByTagName('body')[0];
    }
    if (state && state.show) {
      if (body) {
        body.classList.add('fixed');
      }
    } else {
      if (body) {
        body.classList.remove('fixed');
      }
    }
  }, [state]);

  return (
    <Query query={MENU_STATE}>
      {({ data: { menu } }) => (
        <Spring
          from={{ opacity: 0, pointerEvents: 'none' }}
          to={{
            opacity: menu && menu.show ? 1 : 0,
            pointerEvents: menu && menu.show ? 'auto' : 'none'
          }}
        >
          {props => (
            <MenuWrapper style={props}>
              <MenuIcon
                className="in-menu"
                state={menu}
                useDark={true}
                onClick={() => {
                  client.writeData({
                    data: {
                      menu: {
                        __typename: 'Menu',
                        show: !menu.show
                      }
                    }
                  });
                }}
              />
              <ul>
                <Spring
                  immediate={menu && !menu.show}
                  from={{ opacity: 0, transform: 'translate3d(0, 20px, 0)' }}
                  to={{
                    opacity: menu && menu.show ? 1 : 0,
                    transform:
                      menu && menu.show
                        ? 'translate3d(0, 0, 0)'
                        : 'translate3d(0, 20px, 0)'
                  }}
                  delay={100}
                >
                  {props => (
                    <li>
                      <MenuItem exact to="/" style={props} onClick={hideMenu}>
                        Hjem
                      </MenuItem>
                    </li>
                  )}
                </Spring>
                <Spring
                  immediate={menu && !menu.show}
                  from={{ opacity: 0, transform: 'translate3d(0, 20px, 0)' }}
                  to={{
                    opacity: menu && menu.show ? 1 : 0,
                    transform:
                      menu && menu.show
                        ? 'translate3d(0, 0, 0)'
                        : 'translate3d(0, 20px, 0)'
                  }}
                  delay={200}
                >
                  {props => (
                    <li>
                      <MenuItem
                        to="/produkter"
                        style={props}
                        onClick={hideMenu}
                      >
                        Produkter
                      </MenuItem>
                    </li>
                  )}
                </Spring>
                <Spring
                  immediate={menu && !menu.show}
                  from={{ opacity: 0, transform: 'translate3d(0, 20px, 0)' }}
                  to={{
                    opacity: menu && menu.show ? 1 : 0,
                    transform:
                      menu && menu.show
                        ? 'translate3d(0, 0, 0)'
                        : 'translate3d(0, 20px, 0)'
                  }}
                  delay={300}
                >
                  {props => (
                    <li>
                      <MenuItem to="/om-oss" style={props} onClick={hideMenu}>
                        Om oss
                      </MenuItem>
                    </li>
                  )}
                </Spring>
                <Spring
                  immediate={menu && !menu.show}
                  from={{ opacity: 0, transform: 'translate3d(0, 20px, 0)' }}
                  to={{
                    opacity: menu && menu.show ? 1 : 0,
                    transform:
                      menu && menu.show
                        ? 'translate3d(0, 0, 0)'
                        : 'translate3d(0, 20px, 0)'
                  }}
                  delay={500}
                >
                  {props => (
                    <li>
                      <MenuItem
                        to="/gratis-befaring"
                        style={props}
                        onClick={hideMenu}
                      >
                        Gratis befaring
                      </MenuItem>
                    </li>
                  )}
                </Spring>
                <Spring
                  immediate={menu && !menu.show}
                  from={{ opacity: 0, transform: 'translate3d(0, 20px, 0)' }}
                  to={{
                    opacity: menu && menu.show ? 1 : 0,
                    transform:
                      menu && menu.show
                        ? 'translate3d(0, 0, 0)'
                        : 'translate3d(0, 20px, 0)'
                  }}
                  delay={600}
                >
                  {props => (
                    <li>
                      <MenuItem
                        to="/kontakt-oss"
                        style={props}
                        onClick={hideMenu}
                      >
                        Kontakt oss
                      </MenuItem>
                    </li>
                  )}
                </Spring>
                <Spring
                  immediate={menu && !menu.show}
                  from={{ opacity: 0, transform: 'translate3d(0, 20px, 0)' }}
                  to={{
                    opacity: menu && menu.show ? 1 : 0,
                    transform:
                      menu && menu.show
                        ? 'translate3d(0, 0, 0)'
                        : 'translate3d(0, 20px, 0)'
                  }}
                  delay={700}
                >
                  {props => (
                    <li>
                      <MenuItem
                        to="/personvern"
                        style={Object.assign(props, {
                          marginTop: '30px',
                          fontSize: '16px'
                        })}
                        onClick={hideMenu}
                      >
                        Personvern
                      </MenuItem>
                    </li>
                  )}
                </Spring>
              </ul>
            </MenuWrapper>
          )}
        </Spring>
      )}
    </Query>
  );
});

const MenuWrapper = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  padding: 130px 40px 0 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 1);
  z-index: 9998;

  ul {
    list-style: none;
    float: right;

    li {
      display: block;
      padding: 15px 5px 15px 0;
      text-align: right;
    }
  }
`;

const MenuItem = styled(props => <NavLink {...props} />)`
  flex: 0 0 auto;
  display: inline-block;
  padding-bottom: 5px;
  font-weight: 400;
  font-size: 18px;
  color: #404040;
  text-decoration: none;
  text-align: right;
  border-bottom: solid 3px transparent;

  @media screen and (max-width: 700px) {
    font-size: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    border-bottom: solid 3px ${({ theme }) => theme.colors.primary};
  }
`;

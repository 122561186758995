import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Map } from './Map';

import { Frame, Facebook } from '../ui/icons';

export const Footer = ({ location }) => {
  return (
    <>
      <Map />
      <StyledFooter>
        <StyledContainer>
          <StyledFooterItemWrapper>
            <FooterItem label="Besøksadresse" value="Molinna 1, 2750 Gran" />
            <FooterItem label="Mobil" value="957 80 619" type="phone" />
            <FooterItem label="E-post" value="post@p-o.no" type="email" />
            <FooterItem
              label="Åpningstider"
              value="Åpent etter nærmere avtale"
            />
            <StyledFacebookShareLink href="https://www.facebook.com/portcenteretostlandet/">
              <Facebook className="icon" />
            </StyledFacebookShareLink>
          </StyledFooterItemWrapper>
          <Copyright>
            <span>
              &copy; {new Date().getFullYear()} Portcenteret Østlandet AS{' '}
            </span>
            <StyledFooterLink to="/personvern">
              Personvernerklæring
            </StyledFooterLink>
          </Copyright>
        </StyledContainer>
        <Frame className="frame green" />
        <Frame className="frame blue" />
      </StyledFooter>
    </>
  );
};

const FooterItem = ({ label, value, type }) => {
  return (
    <StyledFooterItem>
      <span>{label}</span>
      {!type ? <span>{value}</span> : null}
      {type === 'email' ? <a href={`mailto:${value}`}>{value}</a> : null}
      {type === 'phone' ? <a href={`tel:${value}`}>{value}</a> : null}
    </StyledFooterItem>
  );
};

const StyledFooterLink = styled(props => <Link {...props} />)`
  margin-bottom: 8px;
  display: inline-block;
  text-decoration: none;
  font-weight: 300;
  color: #666666;
`;

const StyledFacebookShareLink = styled.a`
  width: 20px;
  height: 20px;
  margin-top: 14px;
  .icon {
    fill: #666666;
  }
`;

const StyledFooterItemWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 400px;
`;

const StyledFooterItem = styled.div`
  display: flex;
  flex-flow: column;
  padding: 10px;
  font-size: 0.875rem;
  color: #666666;
  width: 50%;

  @media screen and (max-width: 500px) {
    width: 100%;
    padding-left: 0 !important;
  }

  &:first-child {
    padding-left: 0;
  }

  &:nth-of-type(3) {
    padding-left: 0;
  }

  span {
    &:first-child {
      font-weight: 600;
    }
    &:last-child {
      font-weight: 300;
    }
  }
  a {
    text-decoration: none;
    font-weight: 300;
    color: #666666;
  }
`;

const Copyright = styled.p`
  display: flex;
  flex-flow: column;
  margin-top: 20px;
  float: left;
  font-size: 0.875rem;
  font-weight: 300;
  color: #666666;

  span {
    font-weight: 400;
  }
`;

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  padding: 35px 40px 40px 40px;
  z-index: 2;

  @media screen and (max-width: 600px) {
    padding: 35px 30px 45px 30px;
  }
`;

const StyledFooter = styled.footer`
  position: relative;
  bottom: 0;
  width: 100%;
  min-height: 200px;
  background: white;
  overflow: hidden;

  @media screen and (max-width: 770px) {
    padding-bottom: 65px;
  }

  .frame {
    position: absolute;
    z-index: 1;

    @media screen and (max-width: 770px) {
      &.blue {
        bottom: -70px !important;
        right: -30px !important;
      }
      &.green {
        bottom: -115px !important;
      }
    }

    &.blue {
      right: -20px;
      bottom: 0;
      height: 8.125rem;
      stroke: ${props => props.theme.colors.primary};
    }
    &.green {
      right: 5rem;
      bottom: -2.5rem;
      height: 8.125rem;
      stroke: ${props => props.theme.colors.secondary};
    }
  }
`;

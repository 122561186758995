import { MENU_STATE } from './queries';

export const resolvers = {
  Mutation: {
    toggleMenu: (_, args, { cache }) => {
      const { menu } = cache.readQuery({
        query: MENU_STATE
      });
      const newState = Object.assign(menu, { show: !menu.show });
      cache.writeQuery({
        query: MENU_STATE,
        data: newState
      });
      return null;
    }
  },
  Query: {}
};

import React from 'react';
import styled from 'styled-components';

export const Section = styled.section`
  width: 100%;
  padding: 40px;
  background: ${props =>
    props.colorTheme
      ? props.theme.colors[props.colorTheme ? props.colorTheme : 'primary']
      : 'white'};
  float: left;

  &.top  {
    padding-top: 300px;
  }

  &.top200 {
    padding-top: 200px;
  }
  &.top150 {
    padding-top: 150px;
  }

  &.bottom {
    padding-bottom: 300px;
  }

  &.dark {
    background: rgb(40, 40, 40);
    h1 {
      color: white !important;
    }
    p {
      color: white !important;
    }
    .checkbox-label {
      color: white;
    }
    a {
      color: white;
    }
  }

  h1 {
    margin: 0;
    font-size: 35px;
    font-weight: 600;
    text-align: ${props => (props.left ? 'left' : 'center')};
    color: ${props =>
      !props.colorTheme ? props.theme.colors.primary : 'white'};

    &.black {
      color: #404040;
    }

    @media screen and (max-width: 550px) {
      font-size: 7vw;
    }
  }

  h2,
  h3 {
    color: rgb(50, 50, 50);
  }
  p {
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 300;
    text-align: ${props => (props.left ? 'left' : 'center')};
    color: ${props => (!props.colorTheme ? 'black' : 'white')};

    &.black {
      color: #404040;
      margin-bottom: 25px;
    }
  }

  @media screen and (max-width: 700px) {
    padding: 50px 30px;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { Button } from '../../ui/Button';
import { Section } from '../../layout/Section';
import { ContentFader } from '../../layout/ContentFader';

import motor from '../../../../../public/images/motor.png';

const Heading = styled.div`
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  h1 {
    margin-top: 0;
  }
`;

export const Automatic = ({ history }) => {
  return (
    <>
      <Helmet>
        <title>Portautomatikk - Portcenteret Østlandet</title>
      </Helmet>
      <Section className="top150" left>
        <ContentFader>
          <>
            <Heading>
              <h2>Crawford</h2>
              <h1>Portautomatikk</h1>
            </Heading>
            <p>
              <b>
                Crawfords garasjeporter kan enkelt åpnes med våre MAGIC motorer.
              </b>
            </p>
          </>
          <h2 style={{ fontWeight: '600' }}>Varianter</h2>
          <ContentWrapper>
            <ContentColumn>
              <PortType colorTheme="tertiary">
                <PortColumn className="image">
                  <TopImage image={motor} />
                </PortColumn>
                <PortColumn className="text">
                  <h4>Magic 600</h4>
                  <p>
                    La Crawfords Magic 600 åpne garasjeporten samtidig som du
                    sitter og kobler av etter en lang arbeidsdag.
                  </p>
                  <Button
                    colorTheme="primary"
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(
                          'https://crawfordgarageportar.se/sv/magic%20600/',
                          '_blank'
                        );
                      }
                    }}
                  >
                    Les mer
                  </Button>
                </PortColumn>
              </PortType>
            </ContentColumn>
            <ContentColumn>
              <PortType colorTheme="quadranery">
                <PortColumn className="image">
                  <TopImage image={motor} />
                </PortColumn>
                <PortColumn className="text">
                  <h4 style={{ color: 'black' }}>Magic 1000</h4>
                  <p style={{ color: 'black' }}>
                    Kraftfull, kjapp og stillegående. - Vår Crawford MAGIC 1000
                    løfter til og med de tyngste portene.
                  </p>
                  <Button
                    colorTheme="primary"
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(
                          'https://crawfordgarageportar.se/sv/magic%201000/',
                          '_blank'
                        );
                      }
                    }}
                  >
                    Les mer
                  </Button>
                </PortColumn>
              </PortType>
            </ContentColumn>
          </ContentWrapper>
        </ContentFader>
      </Section>
    </>
  );
};

const TopImage = styled.div`
  background: ${props => `url(${props.image}) no-repeat`};
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 1100px) {
    flex-flow: column;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  width: calc(100% - 20px);
  margin-right: 20px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const PortType = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors[props.colorTheme]};

  @media screen and (max-width: 550px) {
    flex-flow: column;

    .text {
      width: 100%;
      float: left;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    color: white;
    margin: 0;
  }
  p {
    color: white;
  }
`;

const PortColumn = styled.div`
  width: 100%;
  height: auto;

  &.text {
    padding: 20px;
  }
  &.image {
    width: 300px;
    padding-left: 20px;
  }
  img {
    height: 200px;
  }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { animated, Spring } from 'react-spring/renderprops.cjs';

import { LinkButton, ButtonGroup } from '../ui/Button';
import { ProgressiveImage } from '../ui/ProgressiveImage';

export const Top = () => {
  const [loaded, setLoaded] = useState(false);
  return (
    <StyledTop>
      <StyledTopContent>
        <Spring
          delay={600}
          from={{ opacity: 0, transform: 'translate3d(0, -10px, 0)' }}
          to={{
            opacity: loaded ? 1 : 0,
            transform: loaded
              ? 'translate3d(0, 0, 0)'
              : 'translate3d(0, -10px, 0)'
          }}
        >
          {props => (
            <h1 style={props}>Trenger du ny garasjeport til din bolig?</h1>
          )}
        </Spring>
        <Spring
          delay={750}
          from={{ opacity: 0, transform: 'translate3d(0, -10px, 0)' }}
          to={{
            opacity: loaded ? 1 : 0,
            transform: loaded
              ? 'translate3d(0, 0, 0)'
              : 'translate3d(0, -10px, 0)'
          }}
        >
          {props => (
            <h2 style={props}>
              Vi leverer Crawford garasjeporter og tilbehør på hele østlandet.
            </h2>
          )}
        </Spring>
        <Spring
          delay={850}
          from={{ opacity: 0, transform: 'translate3d(0, -10px, 0)' }}
          to={{
            opacity: loaded ? 1 : 0,
            transform: loaded
              ? 'translate3d(0, 0, 0)'
              : 'translate3d(0, -10px, 0)'
          }}
        >
          {props => (
            <ButtonGroup style={props}>
              <LinkButton colorTheme="primary" to="/kontakt-oss">
                Kontakt oss
              </LinkButton>
              <LinkButton colorTheme="secondary" to="/gratis-befaring">
                Få gratis befaring
              </LinkButton>
            </ButtonGroup>
          )}
        </Spring>
      </StyledTopContent>
      <StyledTopInfoOuterWrapper>
        <StyledTopInfoWrapper>
          <div className="col">
            <p>Kontakt oss for gratis befaring</p>
            <a href="tel:95780619">957 80 619</a>
          </div>
        </StyledTopInfoWrapper>
        <StyledTopInfo />
        <StyledAngle />
      </StyledTopInfoOuterWrapper>
      <ProgressiveImage
        src="superior.jpg"
        preview="superior-preview.jpg"
        imageLoaded={() => setLoaded(true)}
      />
    </StyledTop>
  );
};

const StyledAngle = styled.div`
  z-index: 1;
  position: relative;
  background: white;
  bottom: 0;
`;

const StyledTopContent = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  width: 100%;
  padding: 0 40px 0 40px;
  height: 100vh;
  z-index: 1000;

  @media screen and (orientation: landscape) {
    min-height: 800px;
  }

  h1 {
    margin: 0;
    font-size: 55px;
    font-weight: 400;
    color: white;
  }
  h2 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 35px;
    font-weight: 300;
    color: white;
  }

  @media screen and (max-width: 700px) {
    h1 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 25px;
      text-align: center;
    }

    a {
      margin-bottom: 20px;
    }
  }

  @media screen and (min-width: 701px) and (max-width: 1060px) {
    h1 {
      font-size: 45px;
      text-align: center;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 30px;
      text-align: center;
    }
  }
`;

const StyledTop = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  float: left;
  background: url('images/top.jpg') no-repeat;
  background-size: cover;
  background-position: center;

  //@media screen and (max-height: 800px) and (orientation: landscape) {
  //  min-height: 900px;
  //}
`;

const StyledTopInfoOuterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  z-index: 1000;
  overflow: hidden;
  background: rgb(40, 40, 40);

  @media screen and (max-width: 550px) {
    height: 90px;
  }
`;

const StyledTopInfo = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
`;

const StyledTopInfoWrapper = styled.div`
  padding: 0 0 0 30px;
  z-index: 100;

  &::before {
    position: relative;
    top: 4px;
    margin-right: 10px;
    content: '';
    display: inline-block;
    float: left;
    width: 40px;
    height: 40px;
    background: url('icons/phone.svg') no-repeat;
    background-size: contain;
    background-position: center;
  }

  div {
    float: left;
  }

  .col {
    display: flex;
    flex-flow: column;
  }

  p {
    display: block;
    float: left;
    margin: 0;
    color: white;
    font-size: 14px;
    font-weight: 400;
  }
  a {
    display: block;
    float: left;
    margin: 0;
    color: white;
    font-size: 22px;
    font-weight: 600;
    text-decoration: none;
  }

  @media screen and (max-width: 500px) {
    p {
    }
    a {
      font-size: 20px;
    }
  }
`;

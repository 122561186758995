import gql from 'graphql-tag';

export const TOGGLE_MENU = gql`
  mutation ToggleMenu($show: Boolean) {
    toggleMenu(show: $show) @client
  }
`;

export const POST_INSPECTION = gql`
  mutation PostInspection($input: InspectionFormInput) {
    inspection(input: $input) {
      success
    }
  }
`;

export const POST_CONTACT = gql`
  mutation PostContact($input: ContactFormInput) {
    contact(input: $input) {
      success
    }
  }
`;

import React from 'react';
import { Helmet } from 'react-helmet-async';

import { ContactForm } from '../layout/ContactForm';

export const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Kontakt oss - Portcenteret Østlandet</title>
      </Helmet>
      <ContactForm />
    </>
  );
};

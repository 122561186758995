import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { Button } from '../ui/Button';
import { Section } from '../layout/Section';
import { ContentFader } from '../layout/ContentFader';

export const About = () => {
  return (
    <>
      <Helmet>
        <title>Om oss - Portcenteret Østlandet</title>
      </Helmet>
      <Section className="top200" left>
        <ContentFader>
          <>
            <h1>Om oss</h1>
            <p>
              Portcenteret Østlandet har 33 års erfaring i portbransjen, først
              som Crawford forhandler nå som Crawford Center. Vi leverer
              garasjeport til privatbruk, industriporter til industri og
              landbruk, portautomatikk, grindautomatikk og dører til alle
              formål. Vi leverer fleksible løsninger i topp kvalitet og tilbyr
              skreddersydde løsninger etter dine ønsker. Vi har flere montører
              med lang erfaring, og kan tilby nøkkelferdige produkt. Distriktene
              vi dekker er Oppland, søndre Hedmark og øvre Buskerud.
            </p>
            <h2>Portcenteret Østlandet fører Crawford Garasjeporter</h2>
            <p style={{ marginBottom: '20px' }}>
              Crawford har sin opprinnelse i USA i 1920-årene. Til Sverige og
              resten av Europa kom portene med hjelp fra en ung svensk ingeniør.
              Hans navn var Fred Bengtsson, og han reiste i 1956 til USA for å
              finne et agentur med amerikanske produkter for salg i Sverige. Han
              var særlig interessert i garasjeporter, og spesielt
              leddheisporter.
            </p>
            <p>
              Hos Crawford Door Co. i Hoover Road i Detroit fant han det han
              lette etter og returnerte til Sverige med salgsrettighetene for
              Crawfords produktsortiment i hele Europa. På denne siden av
              Atlanterhavet startet han arbeidet i Göteborg i 1960. I 1962
              begynte man med egen utvikling og produksjon av porter, og
              importen fra USA ble avsluttet.
            </p>
          </>
        </ContentFader>
      </Section>
    </>
  );
};

import React from 'react';
import styled from 'styled-components';

import { Query } from 'react-apollo';
import { MENU_STATE } from '../../../client/__graphql__/queries';

export const MenuIcon = ({ className, onClick, useDark }) => {
  return (
    <Query query={MENU_STATE}>
      {({ data: { menu } }) => (
        <MenuIconWrapper onClick={onClick} className={className}>
          <MenuIconLine
            className="top"
            show={menu && menu.show}
            useDark={useDark}
          />
          <MenuIconLine
            className="middle"
            show={menu && menu.show}
            useDark={useDark}
          />
          <MenuIconLine
            className="bottom"
            show={menu && menu.show}
            useDark={useDark}
          />
        </MenuIconWrapper>
      )}
    </Query>
  );
};

const MenuIconLine = styled.span`
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  position: absolute;
  display: block;
  width: 30px;
  height: 2px;
  background: ${props =>
    props.show || props.useDark ? 'rgb(40, 40, 40)' : 'white'};
  transform-origin: center center;

  &.top {
    top: 0;
    bottom: ${props => (props.show ? 0 : 'auto')};
    transform: ${props => (props.show ? 'rotate(45deg)' : 'rotate(0deg)')};
    ${props => (props.show ? ' margin: auto;' : '')}
  }
  &.middle {
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: ${props => (props.show ? 0 : 1)};
  }
  &.bottom {
    top: ${props => (props.show ? 0 : 'auto')};
    bottom: 0;
    transform: ${props => (props.show ? 'rotate(-45deg)' : 'rotate(0deg)')};
    ${props => (props.show ? ' margin: auto;' : '')}
  }
`;

const MenuIconWrapper = styled.div`
  position: relative;
  width: 30px;
  height: 25px;

  &.in-menu {
    position: absolute;
    top: 36px;
    right: 40px;

    @media screen and (max-width: 700px) {
      top: 36px;
      right: 30px;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const BreadCrumbs = ({ show, location, match }) => {
  let getLinkName = param => {
    switch (param) {
      case 'om-oss':
        return 'Om oss';
        break;
      case 'produkter':
        return 'Produkter';
        break;
      case 'kontakt-oss':
        return 'Kontakt oss';
        break;
      case 'personvern':
        return 'Personvern';
        break;
      case 'gratis-befaring':
        return 'Gratis befaring';
        break;
      case 'leddheisport':
        return 'Leddheisport';
        break;
      case 'rulleporter-gitter':
        return 'Rulleporter & gitter';
        break;
      case 'vippeporter':
        return 'Vippeporter';
        break;
      case 'sidegående-leddport':
        return 'Sidegående leddport';
        break;
      case 'portautomatikk':
        return 'Portautomatikk';
        break;
      case 'tilbehør':
        return 'Tilbehør';
        break;
      case 'service':
        return 'Service & reservedeler';
        break;
    }
  };

  if (location && location.pathname !== '/') {
    return (
      <StyledBreadCrumbsWrapper show={show}>
        {match.params.p1 ? (
          <StyledBreadCrumbItem to={`/${match.params.p1}`}>
            {getLinkName(match.params.p1)}
          </StyledBreadCrumbItem>
        ) : null}
        {typeof match.params.p2 !== 'undefined' ? (
          <StyledBreadCrumbItem to={match.params.p2}>
            {getLinkName(match.params.p2)}
          </StyledBreadCrumbItem>
        ) : null}
      </StyledBreadCrumbsWrapper>
    );
  } else {
    return null;
  }
};

const StyledBreadCrumbsWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  padding: 10px 40px;
  background: rgb(40, 40, 40);
  float: left;
  opacity: ${props => (props.show ? 0 : 1)};
  pointer-events: ${props => (props.show ? 'none' : 'auto')};
  user-select: none;
`;

const StyledBreadCrumbItem = styled(props => <NavLink {...props} />)`
  padding: 5px 0;
  margin-right: 14px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  text-decoration: none;
  &:last-child {
    &::after {
      display: none;
    }
  }

  &::after {
    content: '>';
    display: inline-block;
    position: relative;
    margin-left: 20px;
  }
`;

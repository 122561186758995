import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { Button, ButtonGroup, LinkButton } from '../components/ui/Button';
import { Checkbox } from '../components/ui/Checkbox';
import Cookies from 'js-cookie';
import { animated, useSpring } from 'react-spring/web.cjs';
import TagManager from 'react-gtm-module';
import { PrivacyContent } from '../components/containers/Privacy';

export const Privacy = () => {
  const [stats, setStats] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [saved, setSaved] = useState(false);
  const [show, setShow] = useState(false);

  const overlayProps = useSpring({
    delay: !saved ? 500 : 500,
    from: { opacity: 0, pointerEvents: 'none' },
    to: { opacity: show ? 1 : 0, pointerEvents: show ? 'auto' : 'none' }
  });

  const formProps = useSpring({
    delay: !saved ? 1000 : 0,
    from: { opacity: 0, pointerEvents: 'none' },
    to: { opacity: show ? 1 : 0, pointerEvents: show ? 'auto' : 'none' }
  });

  useEffect(() => {
    const isSet = Cookies.get('_po_privacy');
    if (!isSet) {
      setShow(true);
    } else {
      const payload = JSON.parse(isSet);
      if (
        typeof window !== 'undefined' &&
        payload &&
        payload.analytics === true
      ) {
        window.allowAnalytics = payload && payload.analytics === true;
        if (!window.google_tag_manager) {
          TagManager.initialize({
            gtmId: 'GTM-WJDS3QH'
          });
        }
      }
    }
  });
  useEffect(() => {
    if (saved === true) {
      Cookies.set(
        '_po_privacy',
        JSON.stringify({ analytics: stats, performance: true }),
        { expires: 365 }
      );
      setShow(false);
    }
  }, [saved]);
  return (
    <>
      <StyledPrivacyWrapper show={show}>
        {showPrivacy ? (
          <StyledPrivacyTop>
            <PrivacyWrapper>
              <Helmet>
                <body class="fixed" />
              </Helmet>
              <PrivacyContent theme="dark" />
              <ButtonGroup style={{ marginTop: '20px' }}>
                <Button
                  colorTheme="gray"
                  onClick={() => {
                    setShowPrivacy(!showPrivacy);
                    setShowSettings(false);
                  }}
                >
                  Lukk
                </Button>
              </ButtonGroup>
            </PrivacyWrapper>
          </StyledPrivacyTop>
        ) : null}
        {!showPrivacy ? (
          <StyledPrivacyBottom style={formProps}>
            <StyledPrivacy>
              {!showSettings ? <h1>Personvern og innstillinger</h1> : null}
              {showSettings ? (
                <>
                  <h2>Hvilke data kan vi lagre på deg?</h2>
                  <StyledPrivacyCheckboxWrapper>
                    <Checkbox
                      label="Anonym statistikk"
                      labelColor="#fff"
                      checked={stats}
                      onClick={() => setStats(!stats)}
                    />
                  </StyledPrivacyCheckboxWrapper>
                </>
              ) : null}
              {!showSettings ? (
                <>
                  <p style={{ marginBottom: '0' }}>
                    Denne siden bruker cookies / informasjonskapsler for å gjøre
                    din brukeropplevelse bedre.
                  </p>
                  <p style={{ marginTop: '10px' }}>
                    Vi ønsker å lagre anonym data fra din brukersesjon. Ønsker
                    du ikke at vi gjør dette, kan du velge å ikke samtykke under
                    "se alternativer".
                  </p>
                </>
              ) : null}
              <ButtonGroup>
                <Button colorTheme="primary" onClick={() => setSaved(true)}>
                  {showSettings ? 'Lagre' : 'Jeg godtar'}
                </Button>
                <Button
                  colorTheme="gray"
                  onClick={() => {
                    setShowSettings(!showSettings);
                    setShowPrivacy(false);
                  }}
                >
                  {showSettings ? 'Lukk alternativer' : 'Se alternativer'}
                </Button>
                <LinkButton
                  onClick={() => {
                    setShowPrivacy(!showPrivacy);
                    setShowSettings(false);
                  }}
                >
                  {!showPrivacy ? 'Se personvernerklæring' : 'Lukk'}
                </LinkButton>
              </ButtonGroup>
            </StyledPrivacy>
          </StyledPrivacyBottom>
        ) : null}
      </StyledPrivacyWrapper>
      <StyledPrivacyOverlay style={overlayProps} />
    </>
  );
};

const StyledPrivacyTop = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px 50px 20px;
  overflow: scroll;
  float: left;

  p {
    color: white;
  }

  h1 {
    font-weight: 400;
    color: white;
  }
`;
const StyledPrivacyBottom = styled(animated.div)`
  position: absolute;
  bottom: 0;
  width: 100%;
  float: left;
  background: rgba(25, 25, 25, 0.5);
`;

const StyledPrivacyWrapper = styled(animated.div)`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  z-index: 9999;
`;

const PrivacyWrapper = styled.div`
  width: 100%;

  h1 {
    font-size: 25px;
  }

  h3 {
    color: white;
  }
`;

const StyledPrivacy = styled(animated.div)`
  padding: 20px;
  width: 100%;
  height: auto;

  h1 {
    margin-top: 0;
    color: white;
    font-size: 20px;
    font-weight: 400;
  }

  h2 {
    color: white;
    font-weight: 300;
    font-size: 18px;
  }

  p {
    color: white;
    font-weight: 300;
    margin-bottom: 20px;
  }
`;

const StyledPrivacyCheckboxWrapper = styled.div`
  display: flex;
  margin: 10px 0 20px 0;
`;

const StyledPrivacyOverlay = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 9998;
`;

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { Button } from '../../ui/Button';
import { Section } from '../../layout/Section';
import { ContentFader } from '../../layout/ContentFader';

import superior from '../../../../../public/images/superior_1.png';
import smart from '../../../../../public/images/smart.png';
import fjernkontroll from '../../../../../public/images/zendesign.png';

const Heading = styled.div`
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  h1 {
    margin-top: 0;
  }
`;

export const SideLeddport = () => {
  return (
    <>
      <Helmet>
        <title>Sidegående leddport - Portcenteret Østlandet</title>
      </Helmet>
      <Section className="top150" left>
        <ContentFader>
          <>
            <Heading>
              <h2>Crawford</h2>
              <h1>Sidegående leddporter</h1>
            </Heading>
            <p>
              <b>For maksimal bruk av takhøyde.</b>
            </p>
            <p>
              Panelene ruller til siden og inn mot garasjeveggen, og
              skyveleddporten er derfor et praktisk alternativ for alle som
              trenger maksimal plass i taket. I motsetning til andre
              garasjeporter som ville okkupert disse områdene med dørpanelene,
              frigir skyveleddporten denne plassen. For eksempel for lagring av
              hage- eller sportsutstyr.
            </p>
            <p>
              Vi har spesialisert oss på garasjeporter og fordi de er laget for
              å holde, får du hele{' '}
              <b style={{ color: 'rgb(60, 60, 60)' }}>10 års garanti</b> på våre
              garasjeporter, inklusiv alle slitedeler når du kjøper en
              garasjeport fra Crawford.
            </p>
          </>
          <ContentWrapper>
            <Button
              colorTheme="primary"
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.open(
                    'https://crawfordgarasjeporter.no/no/garasjeporter/leddporter/skyveleddporter/',
                    '_blank'
                  );
                }
              }}
            >
              Les mer
            </Button>
          </ContentWrapper>
          <h2 style={{ fontWeight: '600' }}>Tilbehør</h2>
          <ContentWrapper>
            <ContentColumn>
              <PortType style={{ background: 'rgb(230, 230, 230)' }}>
                <PortColumn className="image">
                  <TopImage src={fjernkontroll} />
                </PortColumn>
                <PortColumn className="text">
                  <h4 style={{ color: 'black' }}>Uendelige muligheter</h4>
                  <p style={{ color: 'black' }}>
                    Åpne din sidegående leddport med et lett trykk på
                    håndsenderen, eller bruk kodelås til å åpne din garasjeport.
                    Vi har et stort utvalg av tilbehør du kan velge til din
                    garasjeport.
                  </p>
                  <Button
                    colorTheme="primary"
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(
                          'https://crawfordgarasjeporter.no/no/garasjeporter/leddporter/skyveleddporter/',
                          '_blank'
                        );
                      }
                    }}
                  >
                    Les mer om tilbehør
                  </Button>
                </PortColumn>
              </PortType>
            </ContentColumn>
          </ContentWrapper>
        </ContentFader>
      </Section>
    </>
  );
};

const TopImage = styled.div`
  background: ${props => `url(${props.src}) no-repeat`};
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 1100px) {
    flex-flow: column;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  width: calc(100% - 20px);
  margin-right: 20px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const PortType = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors[props.colorTheme]};

  @media screen and (max-width: 550px) {
    flex-flow: column;

    .text {
      width: 100%;
      float: left;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    color: white;
    margin: 0;
  }
  p {
    color: white;
  }
`;

const PortColumn = styled.div`
  width: 100%;
  height: auto;

  &.text {
    padding: 20px;
  }
  &.image {
    width: 300px;
    padding-left: 20px;
  }
  img {
    height: 200px;
  }
`;

import React, { useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { GlobalStyles } from './common/__config__/globalStyles';
import { withApollo } from 'react-apollo';

import { Home } from './common/components/containers/Home';
import { Inspection } from './common/components/containers/Inspection';
import { About } from './common/components/containers/About';
import { Contact } from './common/components/containers/Contact';
import { Products } from './common/components/containers/Products';
import { Product } from './common/components/containers/Product';

import { Footer } from './common/components/layout/Footer';
import { Header } from './common/components/layout/Header';
import { NoMatch } from './common/components/containers/NoMatch';
import { PrivacyPage } from './common/components/containers/Privacy';

import { Privacy } from './common/utils/privacy';
import { Leddheisport } from './common/components/containers/products/Leddheisport';
import { SideLeddport } from './common/components/containers/products/SideLeddport';
import { Accessories } from './common/components/containers/products/ Accessories';
import { Parts } from './common/components/containers/products/Parts';
import { Automatic } from './common/components/containers/products/Automatic';

const App = withApollo(props => {
  useEffect(() => {
    if (typeof window !== '') {
      window.scroll(0, 0);
    }
  }, [props.location.pathname]);

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', () => {
      props.client.writeData({
        data: {
          scroll: window.pageYOffset
        }
      });
    });
  }

  return (
    <>
      <GlobalStyles />
      <Header {...props} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/om-oss" component={About} />
        <Route exact path="/gratis-befaring" component={Inspection} />
        <Route exact path="/kontakt-oss" component={Contact} />
        <Route exact path="/personvern" component={PrivacyPage} />
        <Route exact path="/produkter" component={Products} />
        <Route path="/produkter/leddheisport" component={Leddheisport} />
        <Route path="/produkter/tilbehør" component={Accessories} />
        <Route path="/produkter/service" component={Parts} />
        <Route path="/produkter/portautomatikk" component={Automatic} />
        <Route path="/produkter/sidegående-leddport" component={SideLeddport} />
        <Route path="/produkter/:page?" component={Product} />
        <Route component={NoMatch} />
      </Switch>
      <Privacy />
      <Footer {...props} />
    </>
  );
});

export default withRouter(App);

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Logo = ({ show, useDark }) => {
  const primary = useDark ? '#444' : '#fff';
  const secondary = '#199FDC';
  return (
    <Link to="/">
      <StyledLogo show={show}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.92 66.92">
          <path
            d="M166.89,80.9a12.8,12.8,0,0,1-.86,4.77,11.16,11.16,0,0,1-2.41,3.8A11,11,0,0,1,159.87,92a12.57,12.57,0,0,1-4.84.9,13.78,13.78,0,0,1-3.08-.34,12.16,12.16,0,0,1-2.71-1L148,93.25a2.58,2.58,0,0,1-1.13.91,3.51,3.51,0,0,1-1.27.26h-1.72l3.16-4.35a11,11,0,0,1-2.89-4,12.74,12.74,0,0,1-1-5.2,13,13,0,0,1,.86-4.77,11.22,11.22,0,0,1,2.42-3.81,11,11,0,0,1,3.76-2.5,12.71,12.71,0,0,1,4.86-.9,13.23,13.23,0,0,1,3.34.41,11.25,11.25,0,0,1,2.9,1.18l.95-1.33c.14-.19.27-.36.39-.5a1.63,1.63,0,0,1,.38-.33,1.46,1.46,0,0,1,.45-.18,2.52,2.52,0,0,1,.6-.06h2.24l-2.9,4A11.19,11.19,0,0,1,166,76,13,13,0,0,1,166.89,80.9Zm-19.3,0a10.78,10.78,0,0,0,.47,3.34,7.17,7.17,0,0,0,1.38,2.54L159,73.65a7.47,7.47,0,0,0-3.95-1,8,8,0,0,0-3.1.57,6.28,6.28,0,0,0-2.34,1.64,7.28,7.28,0,0,0-1.48,2.6A10.62,10.62,0,0,0,147.59,80.9Zm14.83,0a11.42,11.42,0,0,0-.38-3,8,8,0,0,0-1.11-2.4l-9.4,12.93a7.86,7.86,0,0,0,3.5.75,8,8,0,0,0,3.09-.57A6.38,6.38,0,0,0,160.44,87a7.13,7.13,0,0,0,1.47-2.59A10.9,10.9,0,0,0,162.42,80.9Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: secondary }}
          />
          <path
            d="M182.89,73.44a1.21,1.21,0,0,1-.41.51,1.11,1.11,0,0,1-.56.14,1.39,1.39,0,0,1-.73-.25c-.27-.17-.59-.35-1-.56a7.9,7.9,0,0,0-1.29-.56,5.33,5.33,0,0,0-1.74-.25,5,5,0,0,0-1.58.22,3.39,3.39,0,0,0-1.13.61,2.41,2.41,0,0,0-.69.93,3.08,3.08,0,0,0-.23,1.19A2.13,2.13,0,0,0,174,76.8a3.86,3.86,0,0,0,1.23.94,11.74,11.74,0,0,0,1.73.7c.65.2,1.31.42,2,.65a18.71,18.71,0,0,1,2,.81,7,7,0,0,1,1.74,1.17,5.3,5.3,0,0,1,1.22,1.73,5.94,5.94,0,0,1,.46,2.48,8.08,8.08,0,0,1-.54,3,6.89,6.89,0,0,1-1.59,2.42,7.73,7.73,0,0,1-2.55,1.62,9.52,9.52,0,0,1-3.45.59,10.9,10.9,0,0,1-2.21-.22A11.91,11.91,0,0,1,172,92a10.7,10.7,0,0,1-1.87-1,9.59,9.59,0,0,1-1.54-1.26l1.26-2.09a1.57,1.57,0,0,1,.43-.38,1,1,0,0,1,.56-.16,1.53,1.53,0,0,1,.87.33c.32.23.69.47,1.12.74a8.06,8.06,0,0,0,1.5.74,5.91,5.91,0,0,0,2.09.33,4.26,4.26,0,0,0,2.88-.88,3.19,3.19,0,0,0,1-2.54,2.36,2.36,0,0,0-.46-1.51,4,4,0,0,0-1.22-1,10,10,0,0,0-1.74-.67q-1-.27-2-.6a14.93,14.93,0,0,1-2-.78,6.29,6.29,0,0,1-1.74-1.18A5.39,5.39,0,0,1,170,78.35a6.92,6.92,0,0,1-.46-2.7,6.39,6.39,0,0,1,.51-2.5A6.21,6.21,0,0,1,171.49,71a7.22,7.22,0,0,1,2.41-1.51,8.68,8.68,0,0,1,3.25-.57,11,11,0,0,1,3.83.65,8.66,8.66,0,0,1,3,1.81Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: secondary }}
          />
          <path
            d="M204.24,69.18v3.58h-7.05V92.62h-4.36V72.76h-7.09V69.18Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: secondary }}
          />
          <path
            d="M211.37,89h9.39v3.6H207V69.18h4.36Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: secondary }}
          />
          <path
            d="M244.37,92.62H241a1.51,1.51,0,0,1-.93-.28,1.69,1.69,0,0,1-.53-.71l-1.75-4.78h-9.71l-1.75,4.78a1.41,1.41,0,0,1-.51.68,1.39,1.39,0,0,1-.93.31H221.5l9.21-23.44h4.46ZM229.2,83.77h7.47L233.82,76c-.13-.34-.27-.75-.43-1.22s-.31-1-.46-1.53c-.15.55-.3,1.06-.45,1.54s-.28.89-.42,1.24Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: secondary }}
          />
          <path
            d="M249.56,69.2a1.2,1.2,0,0,1,.35.1,1.37,1.37,0,0,1,.3.21,2.51,2.51,0,0,1,.32.37l12.31,15.67a10.81,10.81,0,0,1-.09-1.11c0-.36,0-.7,0-1V69.18h3.85V92.62h-2.26a2,2,0,0,1-.86-.16,2,2,0,0,1-.66-.59L250.53,76.26c0,.35.06.69.08,1s0,.65,0,.94V92.62h-3.84V69.18h2.29Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: secondary }}
          />
          <path
            d="M292.11,80.9a12.48,12.48,0,0,1-.86,4.73,10.62,10.62,0,0,1-6.16,6.13,13.19,13.19,0,0,1-4.84.86H271.3V69.18h8.95a13,13,0,0,1,4.84.87,11,11,0,0,1,3.75,2.41,10.82,10.82,0,0,1,2.41,3.71A12.48,12.48,0,0,1,292.11,80.9Zm-4.46,0a10.9,10.9,0,0,0-.51-3.46,7.29,7.29,0,0,0-1.48-2.59,6.22,6.22,0,0,0-2.33-1.63,7.94,7.94,0,0,0-3.08-.57h-4.56v16.5h4.56a7.94,7.94,0,0,0,3.08-.57A6.34,6.34,0,0,0,285.66,87a7.29,7.29,0,0,0,1.48-2.59A10.9,10.9,0,0,0,287.65,80.9Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: secondary }}
          />
          <path
            d="M310.31,69.18v3.47H299.92v6.5h8.19V82.5h-8.19v6.63h10.39v3.49H295.53V69.18Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: secondary }}
          />
          <path
            d="M330.61,69.18v3.58h-7.05V92.62H319.2V72.76h-7.09V69.18Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: secondary }}
          />
          <path
            d="M27.78,62.53v-35H47q8.5,0,8.51,8.52v5q0,8.52-8.51,8.52H32.79v13Zm5-18h13.9a4,4,0,0,0,2.88-.87,4,4,0,0,0,.88-2.89V36.27a4,4,0,0,0-.88-2.88,4,4,0,0,0-2.88-.88H32.79Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: primary }}
          />
          <path
            d="M92.46,57.52a4.91,4.91,0,0,1-.39,1.94,5,5,0,0,1-2.67,2.68,5,5,0,0,1-2,.39h-20a5,5,0,0,1-5-5v-25a5,5,0,0,1,.39-1.95A4.83,4.83,0,0,1,63.91,29a5.25,5.25,0,0,1,1.6-1.08,4.71,4.71,0,0,1,1.94-.4h20a4.79,4.79,0,0,1,2,.4A5.37,5.37,0,0,1,91,29a4.93,4.93,0,0,1,1.46,3.53Zm-25-25v25h20v-25Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: primary }}
          />
          <path
            d="M129.13,40q0,7.32-6.26,8.35l6.6,14.17h-5.55l-6.5-14h-11v14h-5v-35h19.17q8.5,0,8.51,8.52Zm-22.67,3.51h13.9a4,4,0,0,0,2.88-.88,4,4,0,0,0,.88-2.88V36.27a4,4,0,0,0-.88-2.88,4,4,0,0,0-2.88-.88h-13.9Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: primary }}
          />
          <path
            d="M134.48,27.5h28v5H151v30h-5v-30h-11.5Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: primary }}
          />
          <path
            d="M168.5,32.51a5,5,0,0,1,.39-1.95A4.83,4.83,0,0,1,170,29a5.25,5.25,0,0,1,1.6-1.08,4.71,4.71,0,0,1,1.94-.4h19a4.71,4.71,0,0,1,1.94.4,5.25,5.25,0,0,1,1.6,1.08,4.83,4.83,0,0,1,1.08,1.58,5,5,0,0,1,.39,1.95V37h-5V32.51h-19v25h19V53h5v4.5a5,5,0,0,1-5,5h-19a5,5,0,0,1-5-5Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: primary }}
          />
          <path
            d="M205.53,27.5h25.53v5H210.54v10h17.51v5H210.54v10h20.52v5H205.53Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: primary }}
          />
          <path
            d="M243.56,62.53h-5v-35h5l21.06,27.16V27.5h5v35h-5L243.56,35.36Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: primary }}
          />
          <path
            d="M275.61,27.5h28v5H292.12v30h-5v-30H275.61Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: primary }}
          />
          <path
            d="M367.31,40q0,7.32-6.25,8.35l6.59,14.17h-5.54l-6.5-14h-11v14h-5v-35h19.18q8.51,0,8.5,8.52Zm-22.67,3.51h13.9a3.94,3.94,0,0,0,2.88-.88,3.92,3.92,0,0,0,.88-2.88V36.27a3.92,3.92,0,0,0-.88-2.88,3.94,3.94,0,0,0-2.88-.88h-13.9Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: primary }}
          />
          <path
            d="M375.66,27.5h25.53v5H380.67v10h17.51v5H380.67v10h20.52v5H375.66Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: primary }}
          />
          <path
            d="M405.68,27.5h28v5H422.2v30h-5v-30H405.68Z"
            transform="translate(-27.78 -27.5)"
            style={{ fill: primary }}
          />
          <rect
            x="280.22"
            y="26.5"
            width="25"
            height="9"
            style={{ fill: secondary }}
          />
          <rect
            x="280.22"
            y="0.5"
            width="25"
            height="9"
            style={{ fill: secondary }}
          />
          <rect
            x="280.22"
            y="13.5"
            width="25"
            height="9"
            style={{ fill: secondary }}
          />
        </svg>
      </StyledLogo>
    </Link>
  );
};

const StyledLogo = styled.div`
  opacity: ${props => (props.show ? 0 : 1)};
  svg {
    width: 200px;
  }
`;

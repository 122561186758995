import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { Item, ItemWrapper, WhiteItem } from '../ui/Item';
import { LinkButton, ButtonGroup } from '../ui/Button';
import {
  LeddportIcon,
  RulleportIcon,
  VippeportIcon,
  SideportIcon,
  AutomaticIcon,
  PartsIcon,
  SparePartsIcon
} from '../ui/icons';

import { Section } from '../layout/Section';
import { ContentFader } from '../layout/ContentFader';

export const Products = () => {
  return (
    <>
      <Helmet>
        <title>Produkter - Portcenteret Østlandet</title>
      </Helmet>
      <Section className="top200">
        <ContentFader>
          <>
            <h1>Produkter</h1>
            <p style={{ marginBottom: '50px' }}>
              Vi tilbyr kvalitetsporter og tilbehør fra Crawford garasjeporter.
            </p>
            <ItemWrapper>
              <Item
                to="/produkter/leddheisport"
                className="flex-center"
                icon={LeddportIcon}
                label="Leddheisport"
              />
              {/*<Item
                to="/produkter/rulleporter-gitter"
                className="flex-center"
                icon={RulleportIcon}
                label="Rulleport og gitter"
              />*/}
              {/*<Item
                to="/produkter/vippeporter"
                className="flex-center i165"
                icon={VippeportIcon}
                label="Vippeport"
               />*/}
              <Item
                to="/produkter/sidegående-leddport"
                className="flex-center"
                icon={SideportIcon}
                label="Sidegående leddport"
              />
              <Item
                to="/produkter/portautomatikk"
                className="flex-center"
                icon={AutomaticIcon}
                label="Portautomatikk"
              />
              <Item
                to="/produkter/tilbehør"
                icon={PartsIcon}
                className="flex-center"
                label="Tilbehør"
              />
              <Item
                to="/kontakt-oss"
                icon={SparePartsIcon}
                label="Service og reservedeler"
              />
              <WhiteItem>
                <h3>
                  Finner du ikke det du leter etter? <span>Ring oss</span>, så
                  vil vi hjelpe deg.
                </h3>
              </WhiteItem>
            </ItemWrapper>
          </>
        </ContentFader>
      </Section>
    </>
  );
};

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import markerIcon from '../../../../public/icons/marker.svg';
let L = null;
if (typeof window !== 'undefined') {
  L = require('leaflet');
}

export const Map = () => {
  const mapRef = useRef();

  const init = () => {
    const settings = {
      lat: 60.353469,
      lng: 10.465917,
      zoom: 12
    };

    if (typeof window !== 'undefined') {
      const map = L.map('map', {
        center: [settings.lat, settings.lng],
        zoom: settings.zoom,
        zoomControl: true
      });
      map.dragging.disable();
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();

      const marker = L.icon({
        iconUrl: '/icons/marker.svg',
        iconSize: [30, 47],
        iconAnchor: [15, 45]
      });

      L.marker([settings.lat, settings.lng], { icon: marker }).addTo(map);
      L.tileLayer(
        `https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart_graatone&zoom={z}&x={x}&y={y}`,
        { attribution: '<a href="http://www.kartverket.no/">Kartverket</a>' }
      ).addTo(map);
    }
  };

  useEffect(() => {
    init();
  }, [mapRef]);

  return <StyledMapWrapper id="map" ref={mapRef} />;
};

const StyledMapWrapper = styled.div`
  width: 100%;
  min-height: 500px;
  float: left;
`;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Item = ({ to, className, icon, label }) => {
  const Icon = icon;
  return (
    <StyledItem to={to} className={className}>
      {Icon ? <Icon className="icon" /> : null}
      <h2>{label}</h2>
    </StyledItem>
  );
};

const StyledItem = styled(props => <Link {...props} />)`
  transition: opacity 0.15s ease-in-out, background 0.15s ease-in-out;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column;
  padding: 20px 0;
  width: calc((100% / 4) - 20px);
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 250px;
  min-height: 252px;
  background: rgb(255, 255, 255);
  border-radius: 2px;
  user-select: none;
  opacity: 0.5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 700px) {
    width: 100%;
    margin-right: 0;
  }

  &.flex-center {
    justify-content: center;
  }

  &:hover {
    //background: #b1c628;
    cursor: pointer;
    opacity: 1;

    .icon {
      transform: scale3d(1.1, 1.1, 1.1) translate3d(0, -10px, 0);
    }
  }

  &.i165 {
    svg {
      width: 165px !important;
    }
  }

  .icon {
    transition: transform 0.15s ease-in-out;
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    width: 150px;
  }

  h2 {
    transition: color 0.15s ease-in-out;
    position: absolute;
    bottom: 30px;
    margin: 10px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    color: rgb(40, 40, 40);
  }
`;

export const WhiteItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 20px;
  width: calc((100% / 4) - 20px);
  min-width: 250px;
  min-height: 252px;
  background: white;
  user-select: none;
  color: #666666;

  @media screen and (max-width: 700px) {
    width: 100%;
  }

  h3 {
    font-weight: 300;
    span {
      font-weight: 600;
    }
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  div {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 700px) {
    div {
      margin-right: 0px;
    }
  }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Mutation } from 'react-apollo';
import { POST_CONTACT } from '../../../client/__graphql__/mutations';

import { Section } from './Section';
import { Form, FormSuccess, FormRow, FormColumn } from '../ui/Form';
import { Input } from '../ui/Input';
import { Textarea } from '../ui/Textarea';
import { Button } from '../ui/Button';
import { Checkbox } from '../ui/Checkbox';
import { validateEmail } from '../../utils/helpers';
import { ContentFader } from '../layout/ContentFader';

export const ContactForm = () => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(false);
  const [submitted, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  return (
    <Mutation
      mutation={POST_CONTACT}
      onCompleted={({ contact }) => {
        if (contact && contact.success) {
          setSuccess(true);
        }
      }}
    >
      {(post, { loading }) => (
        <Section className="top200">
          <ContentFader>
            <>
              <h1 className="black">Kontakt oss</h1>
              <p
                className="black"
                style={{ maxWidth: '800px', margin: '20px auto 20px auto' }}
              >
                For ytteligere informasjon, ta kontakt med oss via telefon eller
                send en e-post via vårt kontaktformular under. Du vil få en
                tilbakemelding på din henvendelse så raskt som mulig..
              </p>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  setSubmit(true);
                  if (
                    name &&
                    mobile &&
                    validateEmail(email) &&
                    message &&
                    checked
                  ) {
                    post({
                      variables: {
                        input: {
                          name,
                          mobile,
                          email,
                          message
                        }
                      }
                    });
                  }
                }}
              >
                <FormSuccess
                  show={success}
                  heading="Takk for din henvendelse!"
                  subText="Vi vil kontakte deg innen kort tid."
                />
                <FormRow>
                  <FormColumn>
                    <Input
                      error={submitted && !name}
                      placeholder="Navn"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </FormColumn>
                  <FormColumn>
                    <Input
                      error={
                        submitted && (!mobile || (mobile && mobile.length < 8))
                      }
                      placeholder="Mobil"
                      value={mobile}
                      onChange={e => setMobile(e.target.value)}
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <Input
                      error={
                        (submitted && !email) ||
                        (submitted && email && !validateEmail(email))
                      }
                      placeholder="E-post"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn>
                    <Textarea
                      error={submitted && !message}
                      placeholder="Melding"
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                    />
                  </FormColumn>
                </FormRow>
                <FormRow className="mt20">
                  <FormColumn>
                    <Checkbox
                      checked={checked}
                      error={submitted && !checked}
                      onClick={() => setChecked(!checked)}
                      label="Jeg samtykker til at jeg kan bli kontaktet via mobil eller e-post."
                    />
                  </FormColumn>
                </FormRow>
                <FormRow className="mt20">
                  <FormColumn>
                    <Button colorTheme="primary">Send henvendelse</Button>
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <PrivacyLink
                    to="/personvern"
                    style={{ marginLeft: '0px', marginTop: '20px' }}
                  >
                    Personvernerklæring
                  </PrivacyLink>
                </FormRow>
              </Form>
            </>
          </ContentFader>
        </Section>
      )}
    </Mutation>
  );
};

const PrivacyLink = styled(props => <Link {...props} />)`
  margin-left: 20px;
  color: #404040;
`;

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { Button } from '../../ui/Button';
import { Section } from '../../layout/Section';
import { ContentFader } from '../../layout/ContentFader';

import superior from '../../../../../public/images/superior_1.png';
import smart from '../../../../../public/images/smart.png';
import fjernkontroll from '../../../../../public/images/zendesign.png';
import mottaker from '../../../../../public/images/mottaker.png';
import kodeplate from '../../../../../public/images/kodelaas.png';
import nokkel from '../../../../../public/images/nokkel.png';

const Heading = styled.div`
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  h1 {
    margin-top: 0;
  }
`;

export const Accessories = () => {
  return (
    <>
      <Helmet>
        <title>Tilbehør - Portcenteret Østlandet</title>
      </Helmet>
      <Section className="top150" left>
        <ContentFader>
          <>
            <Heading>
              <h2>Crawford</h2>
              <h1>Tilbehør til portautomatikk</h1>
            </Heading>
            <p>Ubegrenset med muligheter for din port.</p>
          </>
          <h2 style={{ fontWeight: '600' }}>Produkter</h2>
          <ContentWrapper>
            <ContentColumn>
              <PortType colorTheme="tertiary">
                <PortColumn className="image">
                  <TopImage src={mottaker} />
                </PortColumn>
                <PortColumn className="text">
                  <h4>Mottaker</h4>
                  <p>
                    Gjør det mulig å betjene den automatiske garasjeporten på
                    lengre avstand. Kompatibel med MAGIC 600 og 1000, med 868
                    MHz signal og 182-bits AES-kryptering.
                  </p>
                </PortColumn>
              </PortType>
            </ContentColumn>
            <ContentColumn>
              <PortType colorTheme="quadranery">
                <PortColumn className="image">
                  <TopImage src={nokkel} />
                </PortColumn>
                <PortColumn className="text">
                  <h4 style={{ color: 'black' }}>Nøkkelbryter</h4>
                  <p style={{ color: 'black' }}>
                    Nøkkelbrytere er løsningen for den automatiske garasjeporten
                    når du glemmer å ta med deg fjernkontrollen. Til innfelt
                    montering eller overflatemontering.
                  </p>
                </PortColumn>
              </PortType>
            </ContentColumn>
          </ContentWrapper>
          <ContentWrapper>
            <ContentColumn>
              <PortType style={{ background: 'rgb(230, 230, 230)' }}>
                <PortColumn className="image">
                  <TopImage src={kodeplate} />
                </PortColumn>
                <PortColumn className="text">
                  <h4 style={{ color: 'black' }}>Ekstern kodeplate</h4>
                  <p style={{ color: 'black' }}>
                    En praktisk måte å styre adgangssystemene på eksternt, uten
                    kabler. Med fire kanaler kan du ikke bare styre den
                    automatiske garasjeporten, men også opptil tre andre
                    garasjeporter og/eller grinder. Beskyttet mot kodetyveri med
                    128-bits AES-kryptering, 868 MHZ frekvens. Kassen er
                    tilgjengelig i antrasitt eller sølvfarge, utformet for
                    overflatemontering. Trykknapper laget av rustfritt stål med
                    bakgrunnslys Strømforsyning med 9 V batteri.
                  </p>
                </PortColumn>
              </PortType>
            </ContentColumn>
            <ContentColumn>
              <PortType style={{ background: 'rgb(230, 230, 230)' }}>
                <PortColumn className="image">
                  <TopImage src={fjernkontroll} />
                </PortColumn>
                <PortColumn className="text">
                  <h4 style={{ color: 'black' }}>Fjernkontroller</h4>
                  <p style={{ color: 'black' }}>
                    De håndholdte fjernkontrollene kommer med to og fire
                    kommandoer. De har en lett og moderne utforming som tar
                    liten plass i lomma. Kopieringsbeskyttet fjernkontrollsystem
                    med 128 bits AES-kryptering, trådløs frekvens på 868 MHz.
                  </p>
                </PortColumn>
              </PortType>
            </ContentColumn>
          </ContentWrapper>
        </ContentFader>
      </Section>
    </>
  );
};

const TopImage = styled.div`
  background: ${props => `url(${props.src}) no-repeat`};
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 1100px) {
    flex-flow: column;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  width: calc(100% - 20px);
  margin-right: 20px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const PortType = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  min-height: 200px;
  background: ${props => props.theme.colors[props.colorTheme]};

  @media screen and (max-width: 550px) {
    flex-flow: column;

    .text {
      width: 100%;
      float: left;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    color: white;
    margin: 0;
  }
  p {
    color: white;
  }
`;

const PortColumn = styled.div`
  width: 100%;
  height: auto;

  &.text {
    padding: 20px;
  }
  &.image {
    width: 300px;
    padding-left: 20px;
  }
  img {
    height: 200px;
  }
`;
